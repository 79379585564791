<template>
  <v-data-table
    sort-desc
    sort-by="created_at"
    class="elevation-1"
    no-data-text="Keine Daten verfügbar"
    loading-text="Artikel laden..."
    :loading="tableLoading"
    :headers="headers"
    :items="items"
    :items-per-page="30"
    :footer-props="tableFooterProps"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Benutzer</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn
                text
                color="blue darken-1"
                :loading="deleteLoading"
                @click="deleteItemConfirm"
              >OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>

    <template v-slot:item.status.name="{ value }">
      <v-icon v-if="value === 'new'" color="yellow accent-2">mdi-new-box</v-icon>
      <v-icon v-else-if="value === 'accepted'" color="green">mdi-check-outline</v-icon>
      <v-icon v-else-if="value === 'denied'" color="red">mdi-close-octagon</v-icon>
      <span v-else>{{value}}</span>
    </template>

    <template v-slot:item.user.user_name="{ item }">
      <v-btn
        text
        small
        :to="{name: 'admin.users.edit', params: {id: item.user.id}}"
      >
        {{ item.user.user_name }}
      </v-btn>
    </template>

    <template v-slot:item.type_wallet="{ value }">
      <v-icon v-if="value === 'market'">mdi-shopping-outline</v-icon>
      <v-icon v-else>mdi-finance</v-icon>
    </template>

    <template v-slot:item.type_transaction="{ value }">
      <v-icon v-if="value === 'send'" color="green">mdi-cash-plus</v-icon>
      <v-icon v-else color="red">mdi-cash-minus</v-icon>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-icon
        class="mr-2"
        @click="$router.push({name: 'admin.funds_queries.show', params: {id: item.id}})"
      >
        mdi-folder-open-outline
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Admin.FundsQueries.Index',
  data: () => ({
    tableLoading: false,
    deleteLoading: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Date',
        align: 'start',
        value: 'created_at',
      },
      {text: 'Status', value: 'status.name'},
      {text: 'Benutzer', value: 'user.user_name'},
      {text: 'Brieftasche eingeben', value: 'type_wallet'},
      {text: 'Transaktion eingeben', value: 'type_transaction'},
      {text: 'Summe', value: 'sum' },
      { text: 'Aktionen', value: 'actions', sortable: false, align: 'end' },
    ],
    tableFooterProps: {
      'items-per-page-options': [15,30,50,100,-1]
    },
    deleteItemId: null,
  }),

  computed: {
    ...mapGetters('fundsQueries', {
      items: 'getAll'
    }),
  },

  created () {
    this.initialize()
  },

  methods: {
    ...mapActions('fundsQueries', {
      loadItems: 'index',
      deleteItem: 'destroy'
    }),
    async initialize () {
      this.tableLoading = true

      try {
        await this.loadItems()
      }
      finally {
        this.tableLoading = false
      }

    },

    deleteItem (item) {
      this.deleteItemId = item.id
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      this.deleteLoading = true

      try {
        await this.deleteItem(this.deleteItemId)
        this.closeDelete()

        await this.initialize()
      }
      finally {
        this.deleteLoading = false
      }
    },

    closeDelete () {
      this.dialogDelete = false
      this.deleteItemId = null
    },
  },
}
</script>

<style scoped>

</style>
