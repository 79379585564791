<template>
  <v-container>

  <v-form
    ref="form"
    v-model="isFormValid"
    @submit.prevent="update"
  >
    <v-card
      max-width="400"
      class="mx-auto"
      :loading="cardLoading"
    >

      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Summe"
                prefix="$"
                :rules="sumRules"
                v-model="sum"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="$router.push({name: 'admin.market_transactions.index'})"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="blue darken-1"
          type="submit"
          :loading="saveLoading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Admin.MarketTransactions.Edit",
  data: () => ({
    cardLoading: false,
    saveLoading: false,
    isFormValid: false,
    title: 'Transaktion bearbeiten',
    sum: '',
    sumRules: [
      v => !!v || 'Summe ist erforderlich',
    ],
  }),
  computed: {
    ...mapGetters('marketTransactions', {
      transaction: 'getOne',
    }),
  },
  async mounted() {
    await this.initComponent()
  },
  methods: {
    ...mapActions('marketTransactions', {
      loadTransaction: 'show',
      updateTransaction: 'update',
      loadCategories: 'getCategories'
    }),

    async initComponent() {
      try {
        this.cardLoading = true
        await this.loadTransaction(this.$route.params.id)

        this.initItem()
      }
      finally {
        this.cardLoading = false
      }
    },

    initItem() {
      let item = Object.assign({}, this.transaction)

      this.sum = item.sum
    },

    async update() {
      this.formResetValidation()

      setTimeout(() => {
        this.formValidate()

      },200)

      if (!this.isFormValid) {
        return
      }

      this.saveLoading = true
      try {
        let payload = {
          sum: this.sum,
        }

        let res = await this.updateTransaction({
          id: this.$route.params.id,
          payload
        })

        if (res) {
          await this.$router.push({name: 'admin.market_transactions.index'})
        }
      }
      finally {
        this.saveLoading = false
      }

    },
    formValidate () {
      return this.$refs.form.validate()
    },
    formReset () {
      this.$refs.form.reset()
    },
    formResetValidation () {
      this.$refs.form.resetValidation()
    },
  }
}
</script>

<style scoped>

</style>
