<template>
  <div>
    <v-btn
      fixed
      bottom
      right
      fab
      class="d-md-none"
      @click="sheet = true"
    >
      <v-icon>mdi-menu</v-icon>
    </v-btn>
    <v-bottom-sheet v-model="sheet">
      <v-list>
        <v-subheader>Menu</v-subheader>

        <v-list-item
          exact
          v-for="page in pages" :key="page.title"
          :to="{name: page.path}"
          @click="sheet = false"
        >
          <v-list-item-icon>
            <v-icon>{{ page.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ page.title}}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-bottom-sheet>
  </div>
</template>

<script>
export default {
  name: 'Components.BottomMenu',
  props: ['auth', 'pages'],
  data: () => ({
    sheet: false,
  }),
}
</script>

<style scoped>

</style>
