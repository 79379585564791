
import Index from '@/views/admin/companies/Index'
import Create from '@/views/admin/companies/Create'
import Edit from '@/views/admin/companies/Edit'

export default [
  {
    path: '/admin/companies',
    name: 'admin.companies.index',
    component: Index,
    meta: { layout: 'admin'},
  },
  {
    path: '/admin/companies/create',
    name: 'admin.companies.create',
    component: Create,
    meta: { layout: 'admin'},
  },
  {
    path: '/admin/companies/:id/edit',
    name: 'admin.companies.edit',
    component: Edit,
    meta: { layout: 'admin'},
  },
]
