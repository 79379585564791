<template>
  <div>
    <v-data-table
      show-group-by
      group-by="category_name"
      sort-by="name"
      item-key="id"
      class="s-data-table elevation-1"
      no-data-text="Keine Daten verfügbar"
      loading-text="Artikel laden..."
      :loading="tableLoading"
      :headers="headers"
      :items="companies"
      :items-per-page="30"
      :footer-props="tableFooterProps"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Firmen</v-toolbar-title>
          <v-divider
            class="mx-4"
            inset
            vertical
          ></v-divider>
          <v-spacer></v-spacer>

          <v-btn
            dark
            color="primary"
            class="mb-2"
          :to="{name: 'admin.companies.create'}"
          >
            Neue Firma
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn
                  text
                  color="blue darken-1"
                  :loading="deleteLoading"
                  @click="deleteItemConfirm"
                >OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.commission="{ value }">
        {{ value | appendSymbol('%') }}
      </template>
      <template v-slot:item.is_deactivated="{ value }">
        {{ value ? 'Yes': 'No' }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="text-no-wrap">

          <v-icon
            class="mr-3"
            @click="$router.push({name: 'admin.companies.edit', params: {id: item.id}})"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Admin.Companies.Index',
  data: () => ({
    tableLoading: false,
    deleteLoading: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Name',
        align: 'start',
        value: 'name',
        groupable: false,
      },
      { text: 'Kategorie', value: 'category_name' },
      { text: 'Webseite', value: 'url', groupable: false },
      { text: 'Kommission', value: 'commission', groupable: false },
      { text: 'Deaktiviert', value: 'is_deactivated', groupable: false },
      { text: 'Aktionen', value: 'actions', sortable: false },
    ],
    tableFooterProps: {
      'items-per-page-options': [15,30,50,100,-1]
    },
    deleteItemId: null,
  }),

  computed: {
    ...mapGetters('companies', ['companies']),
  },

  created () {
    this.initialize()
  },

  methods: {
    ...mapActions('companies', {
      loadCompanies: 'index',
      deleteCompany: 'destroy'
    }),
    async initialize () {
      this.tableLoading = true

      try {
        await this.loadCompanies()
      }
      finally {
        this.tableLoading = false
      }

    },

    deleteItem (item) {
      this.deleteItemId = item.id
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      this.deleteLoading = true

      try {
        await this.deleteCompany(this.deleteItemId)
        this.closeDelete()

        await this.initialize()
      }
      finally {
        this.deleteLoading = false
      }
    },

    closeDelete () {
      this.dialogDelete = false
      this.deleteItemId = null
    },
  },
}
</script>

<style lang="scss" scoped>
.theme--dark.s-data-table {
  &::v-deep tbody tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.v-row-group__header) {
    background-color: #424242 !important;
  }
}
.theme--light.s-data-table {
  &::v-deep tbody tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper):not(.v-row-group__header) {
    background-color: #E0E0E0 !important;
  }
}
</style>
