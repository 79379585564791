import {versionUri} from '@/services/config'

const state = {
    companies: [],
    company: null,
    categories: [],
    apiUrl: versionUri + 'admin/companies'
}

const getters = {
    companies: (state) => state.companies,
    company: (state) => state.company,
    categories: (state) => state.categories,
}

const mutations = {
    companies(state, payload) {
        state.companies = payload;
    },
    company(state, payload) {
        state.company = payload;
    },
    categories(state, payload) {
        state.categories = payload;
    },
}

const actions = {
    async index(context) {
        try {
            let response = await axios.get(context.state.apiUrl)

            context.commit('companies', response.data.data)
        }
        catch (error) {
            throw new Error(error)
        }
    },
    async store(context, payload) {
        try {
            let response = await axios.post(context.state.apiUrl, payload)
            console.log('companies store response', response)

            return response.status === 200
        }
        catch (error) {
            throw new Error(error)
        }
    },
    async show(context, id) {
        try {
            let response = await axios.get(`${context.state.apiUrl}/${id}`)
            console.log('companies show response', response)

            context.commit('company', response.data.data)
        }
        catch (error) {
            throw new Error(error)
        }
    },
    async update(context, {id, payload}) {
        try {
            let response = await axios.patch(`${context.state.apiUrl}/${id}`, payload)
            console.log('companies update response', response)

            return response.status === 200
        }
        catch (error) {
            throw new Error(error)
        }
    },
    async destroy(context, id) {
        try {
            let response = await axios.delete(`${context.state.apiUrl}/${id}`)
            console.log('companies destroy response', response)

            return response.status === 200
        }
        catch (error) {
            throw new Error(error)
        }
    },
    async getCategories(context) {
        try {
            let response = await axios.get(`${context.state.apiUrl}/get-categories`)

            context.commit('categories', response.data.data)
        }
        catch (error) {
            throw new Error(error)
        }
    },
    async getByCategories(context) {
        try {
            let response = await axios.get(`${context.state.apiUrl}/get-by-categories`)

            return response.data.data
        }
        catch (error) {
            throw new Error(error)
        }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
