<template>
  <div>

<!-- ⬇⬇⬇ Dialogs ⬇⬇⬇ -->
    <v-dialog
      v-model="dialogMarketMsg"
      max-width="400"
    >
      <v-card
        :loading="loadingCardMarket"
      >
        <v-card-title class="text-h5">
          Marktplatz Liste
        </v-card-title>
        <v-card-text>

          <v-expand-transition>
            <div v-if="categories.length">
              <div v-for="(category, idx) in categories">
                {{ category.name }}
                <ul>
                  <li v-for="company in category.data">
                    <a :href="company.url" target="_blank">{{ company.name }}</a>
                  </li>
                </ul>

                <br v-if="idx !== categories.length - 1">
              </div>
            </div>
          </v-expand-transition>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="green darken-1"
            text
            @click="dialogMarketMsg = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogEditProfile"
      max-width="400"
    >
      <EditProfile
        @closed="dialogEditProfile = false"
        @updated="dialogEditProfile = false"
      />
    </v-dialog>
<!-- ⬆⬆⬆ Dialogs ⬆⬆⬆ -->


    <div class="text-center">
      <v-chip
        label
        outlined
        @click="copyLink"
      >
        <span class="s-dark-color">CROWD Persönlicher Empfehlungslink</span>
        <v-icon
          right
        >mdi-content-copy</v-icon>
      </v-chip>
    </div>

    <div class="s-title-block mt-3 mt-sm-10 text-center green--text text--darken-1">
      <div>HERZLICH WILLKOMMEN !</div>
      <div>EINE PLATTFORM DIE ALLEN PARTNER GEWINNE UND</div>
      <div>EINKOMMEN BIETET</div>
    </div>

    <div class="mt-3 mt-sm-10 text-center">{{auth.first_name}} {{auth.last_name}}</div>

    <div class="mt-3 mt-sm-10"></div>

    <div style="justify-content: space-evenly" class="d-flex">

      <div style="flex-basis: 25%" class="text-center">
        <v-btn
          class="d-none d-md-inline"
          @click="dialogEditProfile = true"
        >
          <v-icon left>mdi-card-account-details</v-icon>
          Profil
        </v-btn>
      </div>

      <div class="d-flex justify-space-between" style="width: 500px;">
        <div style="flex-basis: 45%" >
          <v-btn
            block
            :outlined="showBlock === 'market'"
            @click="showBlock = showBlock === 'market' ? 'main': 'market'"
          >
            {{ showBlock === 'market' ? 'zurück' : 'Marktplatz'}}
          </v-btn>
        </div>

        <div style="flex-basis: 45%">
          <v-btn
            block
            :outlined="showBlock === 'crowd'"
            @click="showBlock = showBlock === 'crowd' ? 'main': 'crowd'"
          >
            {{ showBlock === 'crowd' ? 'zurück' : 'CROWD'}}
          </v-btn>
        </div>
      </div>

      <div style="flex-basis: 25%" class="text-center">
          <v-btn
            class="d-none d-md-inline"
            @click="showCategories"
          >
            Marktplatz Liste
            <v-icon right>mdi-chat-question-outline</v-icon>
          </v-btn>
      </div>
    </div>

    <v-divider
      class="d-none d-sm-block mt-3"
    />
    <div style="justify-content: space-evenly" class="mt-3 d-flex">

      <div style="flex-basis: 25%" class="text-center"></div>

      <div class="" style="width: 500px;">

        <v-chip
          label
          light
          class="s-title-chip"
          color="amber"
        >
          Ü B E R S I C H T
        </v-chip>

        <v-slide-y-transition mode="out-in">

          <v-progress-linear
            v-if="showBlock === 'loading'"

            indeterminate
            rounded
            color="grey darken-3"
            height="10"
          ></v-progress-linear>

          <HomeTotal
            v-else-if="showBlock === 'main'"
          />

          <MarketTotal
            v-else-if="showBlock === 'market'"
          />

          <CrowdTotal
            v-else-if="showBlock === 'crowd'"
          />
        </v-slide-y-transition>
      </div>

      <div style="flex-basis: 25%" class="text-center">
        <v-btn
          class="d-none d-md-inline-flex"
          :to="{name: 'account.crowd'}"
        >
          CROWD-Struktur
          <v-icon right>mdi-sitemap</v-icon>
        </v-btn>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import HomeTotal from "@/views/account/components/HomeTotal";
import MarketTotal from "@/views/account/components/MarketTotal";
import CrowdTotal from "@/views/account/components/CrowdTotal";
import {versionUri} from '@/services/config'
import EditProfile from '@/views/account/components/EditProfile'

export default {
  name: 'Account.Home',
  components: {
    HomeTotal,
    MarketTotal,
    CrowdTotal,
    EditProfile
  },
  data: () => ({
    refLink: null,
    showBlock: 'loading',
    dialogMarketMsg: false,
    dialogCrowdMsg: false,
    dialogEditProfile: false,
    loadingCardMarket: false,
    categories: [],
  }),
  mounted() {
    this.initRefLink()
    this.loadAccountTotals()
  },
  computed: {
    ...mapGetters('auth', {
      auth: 'currentUser'
    }),
    ...mapGetters('account', ['totals']),
  },
  methods: {
    ...mapActions('account', ['loadTotals']),

    async copyLink() {
      await this.copyToClipboard(this.refLink)
    },
    initRefLink() {
      this.refLink = location.origin + '/register?ref=' + this.auth.referral_token
    },

    async loadAccountTotals() {
      this.showBlock = 'loading'

      let response = await this.loadTotals()
      console.log('loadAccountTotals response', response)

      this.showBlock = 'main'
    },

    async copyToClipboard(val) {
      try {
        await navigator.clipboard.writeText(val);
        await this.$store.dispatch('snackBar/run', 'kopiert')
      }
      catch($e) {
        alert('Cannot copy');
      }
    },

    async showCategories() {
      this.dialogMarketMsg = true

      if (!this.categories.length) {
        await this.loadCategories()
      }
    },

    async loadCategories() {
      this.loadingCardMarket = true

      try {
        let url = versionUri + 'account/companies/by-categories'
        let response = await axios.get(url)

        this.categories = response.data.data
        console.log('items', this.categories)
      }
      catch (error) {
        throw new Error(error)
      }
      finally {
        this.loadingCardMarket = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.s-title-chip {
  font-weight: bold;
  width: 100%;
  justify-content: center;
}
.s-review-block {
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;
}

@media (max-width: 599px) {
  .s-title-block {
    font-size: 13px;
  }

}
</style>
