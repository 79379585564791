<template>
  <v-app>

    <v-main>
      <SnackBar/>

        <v-slide-x-transition mode="out-in">
          <router-view></router-view>
        </v-slide-x-transition>
    </v-main>

    <v-footer
      app
      absolute
      class="justify-center pa-5 pa-md-16"
    >

      <div
        class="text-center"
      >
        <v-btn
          small
          block
          outlined
          class="my-2 grey--text text--darken-2"
        >
          DEUTSCH
        </v-btn>
        <v-btn
          small
          block
          outlined
          class="my-2 grey--text text--darken-2"
        >
          OFFICIAL GG TELEGRAM
        </v-btn>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import SnackBar from "@/components/SnackBar";

export default {
  name: 'PageLayout',
  components: { SnackBar },
  methods: {
  }
}
</script>

<style lang="scss">

.s-dark-color {
  color: #7F7F7F;
}
.s-cur-pointer {
  cursor: pointer;
}
@media (max-width: 599px) {
    div.s-subtitle {
      font-size: 10px !important;
    }
}

</style>

<style lang="scss">
</style>
