<template>
  <v-data-table
    sort-desc
    sort-by="created_at"
    class="elevation-1"
    no-data-text="Keine Daten verfügbar"
    loading-text="Artikel laden..."
    :loading="tableLoading"
    :headers="headers"
    :items="transactions"
    :items-per-page="30"
    :footer-props="tableFooterProps"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Markttransaktionen</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        Gesamt: {{ totalSum | numberFormat }}
        <v-spacer></v-spacer>

        <v-btn
          dark
          color="primary"
          class="mb-2"
        :to="{name: 'admin.market_transactions.create'}"
        >
          Neue Transaktion
        </v-btn>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn
                text
                color="blue darken-1"
                :loading="deleteLoading"
                @click="deleteItemConfirm"
              >OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.sum="{ item }">
      {{ item.sum | numberFormat }}
    </template>
    <template v-slot:item.actions="{ item }">
      <div class="text-no-wrap">
        <v-icon
          class="mr-3"
          @click="$router.push({name: 'admin.market_transactions.edit', params: {id: item.id}})"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Admin.MarketTransactions.Index',
  data: () => ({
    tableLoading: false,
    deleteLoading: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Date',
        align: 'start',
        value: 'created_at',
      },
      { text: 'Summe', value: 'sum' },
      { text: 'Aktionen', value: 'actions', sortable: false, align: 'end' },
    ],
    tableFooterProps: {
      'items-per-page-options': [15,30,50,100,-1]
    },
    deleteItemId: null,
  }),

  computed: {
    ...mapGetters('marketTransactions', {
      transactions: 'getAll'
    }),

    totalSum() {
      let sum = 0

      for (let key in this.transactions) {
        sum += this.transactions[key].sum
      }

      return sum
    }
  },

  created () {
    this.initialize()
  },

  methods: {
    ...mapActions('marketTransactions', {
      loadTransactions: 'index',
      deleteTransaction: 'destroy'
    }),
    async initialize () {
      this.tableLoading = true

      try {
        await this.loadTransactions()
      }
      finally {
        this.tableLoading = false
      }

    },

    deleteItem (item) {
      this.deleteItemId = item.id
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      this.deleteLoading = true

      try {
        await this.deleteTransaction(this.deleteItemId)
        this.closeDelete()

        await this.initialize()
      }
      finally {
        this.deleteLoading = false
      }
    },

    closeDelete () {
      this.dialogDelete = false
      this.deleteItemId = null
    },
  },
}
</script>

<style scoped>

</style>
