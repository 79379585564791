<template>
  <div :class="{'s-background-dark': isDarkTheme}">
    <v-slide-x-transition mode="out-in">
      <component :is="layout"></component>
    </v-slide-x-transition>
  </div>
</template>

<script>

import AdminLayout from '@/views/layouts/AdminLayout'
import PageLayout from '@/views/layouts/PageLayout'
import AccountLayout from '@/views/layouts/AccountLayout'

export default {
  name: 'App',
  components: {
    AdminLayout,
    PageLayout,
    AccountLayout
  },
  data: () => ({
    windowsThemeDark: null,
  }),

  mounted() {
    this.hidePreloader()
    this.listenerWindowsTheme()
    this.isWindowsThemeDark()
  },

  computed: {
    layout() {
      return (this.$route.meta.layout || 'page') + '-layout'
    },
    isDarkTheme() {
      return this.$vuetify.theme.dark
    }
  },
  watch: {
    layout() {
      this.$store.commit('hideSideBar')
    }
  },
  methods: {
    hidePreloader() {
      let preloader = document.querySelector('.app-preloader');
      preloader.classList.add('_hide')
      setTimeout(() => {
        preloader.classList.add('_none')
      },2000)
    },
    listenerWindowsTheme() {
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
        console.log('change windows dark theme', event.matches)
        this.windowsThemeDark = event.matches // boolean
      });
    },
    isWindowsThemeDark() {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.windowsThemeDark = true
        return
      }

      this.windowsThemeDark = false
    }
  }
};
</script>

<style lang="scss" scoped>
.s-background-dark {
  background-color: #121212;
}
</style>
