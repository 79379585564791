
import Index from '@/views/admin/funds_queries/Index'
import Show from '@/views/admin/funds_queries/Show'

export default [
  {
    path: '/admin/funds_queries',
    name: 'admin.funds_queries.index',
    component: Index,
    meta: { layout: 'admin'},
  },
  {
    path: '/admin/funds_queries/:id/show',
    name: 'admin.funds_queries.show',
    component: Show,
    meta: { layout: 'admin'},
  },
]
