<template>
  <v-container >
    <v-card
      :loading="loadingData"
    >
      <v-card-title>CROWD</v-card-title>
      <v-card-text>
        <v-alert
          dense
          style="font-size: 14px"
        >
          Ihr Umsatz: {{ profit | numberFormat }}
        </v-alert>

        <v-row>
          <v-col cols="4">Stufe</v-col>
          <v-col cols="4">Prozent</v-col>
          <v-col cols="4">Überreste USDT</v-col>
        </v-row>

        <div v-for="step in stepBlocks" :key="step.num">

          <v-slide-y-transition>
            <v-row>
              <v-col cols="4">
                <v-alert
                  dense
                  class="text-center mb-0"
                  :color="step.active ? 'green' : ''"
                >{{ step.title }}</v-alert>
              </v-col>
              <v-col cols="4" class="align-self-center">
                <v-alert
                  dense
                  class="text-center mb-0"
                  :color="step.active ? 'green' : ''"
                >{{ step.percent }}%</v-alert>
              </v-col>
              <v-col cols="4">
                <v-alert
                  dense
                  class="text-center mb-0"
                  :class="{'s-loading-alert': step.loading}"
                  :color="step.done ? 'green' : ''"
                >

                  <v-icon
                    v-if="step.done"
                    small
                    style="margin-top: -1px"
                  >mdi-checkbox-marked-circle</v-icon>

                  <v-progress-linear
                    v-else-if="step.loading"

                    rounded
                    color="green"
                    background-color="transparent"
                    height="100%"
                    :value="step.countPercent"
                  >
                    {{ step.remainsSum | numberFormat }}
                  </v-progress-linear>

                  <div v-else>---</div>

                </v-alert>
              </v-col>
            </v-row>
          </v-slide-y-transition>
        </div>
      </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import {versionUri} from '@/services/config'

export default {
  name: 'Admin.Home',
  data: () => ({
    loadingData: true,
    countFundsQueries: null,
    profit: 0,
    level: null,
    levels: null,

    stepBlocks: [
      {num: 1, title: 'I', percent: 10, total: 5000, active: false},
      {num: 2, title: 'II', percent: 15, total: 10000, active: false},
      {num: 3, title: 'III', percent: 20, total: 30000, active: false},
      {num: 4, title: 'IV', percent: 25, total: 90000, active: false},
      {num: 5, title: 'V', percent: 30, total: 250000, active: false},
      {num: 6, title: 'VI', percent: 35, total: 1000000, active: false},
      {num: 7, title: 'VII', percent: 45, total: 5000000, active: false},
      {num: 8, title: 'VIII', percent: 55, total: 15000000, active: false},
      {num: 9, title: 'IX', percent: 65, total: 35000000, active: false},
      {num: 10, title: 'X', percent: 70, total: null, active: false},
    ],
  }),
  async mounted() {
    await this.loadCrowdData()
    this.calcStepRemains()
  },
  methods: {
    async loadCrowdData() {
      this.loadingData = true

      try {
        let url = versionUri + 'admin/dashboard/crowd'
        let response = await axios.get(url)
        console.log('admin home loadCrowdData response', response)

        if (response.status === 200) {
          this.init(response.data.data)
        }
      }
      finally {
        this.loadingData = false
      }
    },
    init(data) {
      this.profit = data.crowdProfit
      this.level = data.currentLevel
      this.levels = data.levels
    },
    calcPercent(val1, val2) {
      return Math.ceil((val1 / val2) * 100)
    },
    calcStepRemains() {
      let steps = this.stepBlocks

      for (let i = 0; i < steps.length; i++) {
        if (!steps[i].total) {
          continue
        }

        let remainsSum = steps[i].total - this.profit
        let countPercent = this.calcPercent(this.profit, steps[i].total)

        remainsSum = remainsSum >= 0 ? remainsSum : 0
        countPercent = countPercent <= 100 ? countPercent : 100

        steps[i].remainsSum = remainsSum
        steps[i].countPercent = countPercent
        steps[i].active = this.level.num === steps[i].num
        steps[i].done = countPercent === 100
        steps[i].loading = countPercent >= 0 && countPercent < 100
      }

      this.stepBlocks = steps
    },
  }
}
</script>

<style lang="scss" scoped>

.s-loading-alert {
  padding: 0;

  &::v-deep .v-alert__content {
     height: 34px;
  }
}

</style>
