<template>

  <v-container class="">
    <div class="s-first-screen mx-auto text-center d-flex flex-column">
      <Header/>

      <v-spacer/>

      <div class="s-font-block grey--text text--lighten-1 text-subtitle-2 text-md-h6 text-lg-h5">
        <div>VERNETZEN WIR UNS!</div>
        <div>MENSCHEN VERNETZEN SICH DIE GANZE ZEIT. SIE HOLEN SICH RAT, KOMMUNIZIEREN</div>
        <div>MIT FREUNDEN IN CHATS UND TEILEN IN SOZIALEN NETZWERKEN</div>
        <div>INFORMATIONEN, DIE SIE FÜR RELEVANT HALTEN.</div>

      </div>

      <v-spacer/>

      <div class="mt-5 s-video-wrapper">
        <video
          controls
          preload="metadata"
          poster="/images/hqdefault.jpg"
          ref="videoRef"
        >
          <source
            src="/video/demo.mp4"
            type="video/mp4"
          >
          Your browser doesn't support HTML5 video tag.
        </video>
      </div>

      <div style="flex-grow: 3"></div>

    </div>

    <div class="s-second-screen text-center d-flex flex-column mx-auto">

      <div class="mt-5 mt-md-16">
        <v-icon class="s-icon-camera">mdi-camera-iris</v-icon>
      </div>

      <v-spacer/>

      <div v-if="isAuthenticated">
        <v-btn :to="getPageName()">Go to Account</v-btn>
      </div>

      <div
        v-else
        class="s-wrapper-auth-buttons"
      >
        <div>
          <v-btn
            block
            rounded
            :x-large="$vuetify.breakpoint.mdAndUp"
            class="my-2 s-btn"
            color="success"
            :to="{name: 'page.register'}"
          >Partner werden</v-btn>
          <v-btn
            block
            rounded
            :x-large="$vuetify.breakpoint.mdAndUp"
            class="my-2 s-btn"
            color="success"
            :to="{name: 'page.login'}"
          >Login</v-btn>
        </div>
      </div>

      <div style="flex-grow: 2"></div>
    </div>


  </v-container>
</template>

<script>

import {mapGetters} from "vuex";
import Header from '@/views/pages/components/Header'

export default {
  name: 'Page.Home',
  components: { Header },
  data: () => ({
  }),
  computed: {
    ...mapGetters('auth', [
      'currentUser',
      'isAuthenticated'
    ]),
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    getPageName() {
      let name = this.currentUser.role === 'admin'
        ? 'admin.home'
        : 'account.home'

      return {name: name}
    }
  }
}
</script>

<style lang="scss" scoped>
.s-first-screen {
  min-height: calc(100vh - 13px);

  max-width: 1265px;
}

.s-second-screen {
  min-height: calc(100vh - 220px);

  .s-wrapper-auth-buttons {
    & > div {
      max-width: 600px;
      margin: auto;
    }
  }
}
.s-font-block {
}
.s-bottom-text {
  font-family: 'Dancing Script', cursive;
}
.s-icon-camera {
  font-size: 150px;
}

  .s-block-frame {
    padding: 20px;
    border-radius: 15px;
    border: 1px solid #404040;
    font-size: 14px;
    margin-left: auto;
    margin-right: auto;
  }

  .s-video-wrapper {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 15px;

    video {
      min-height: 222px;
      width: 100%;
      border-radius: 15px;
    }
  }

  .s-btn::v-deep .v-btn__content{
    color: #000000;
  }

  @media (max-width: 959px) {
    .s-second-screen {
      min-height: calc(100vh - 130px);
    }
  }

  @media (max-width: 599px) {
    .s-icon-camera {
      font-size: 100px;
    }

    .s-block-frame {
      padding: 10px;
    }
    .s-font-block {
      font-size: 12px;

      .s-block-frame {
        font-size: inherit;
      }
    }
  }
</style>
