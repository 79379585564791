<template>
  <v-container>

    <v-form
      ref="form"
      v-model="isFormValid"
      @submit.prevent="save"
    >
      <v-card
        max-width="400"
        class="mx-auto"
      >
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  prefix="$"
                  label="Summe"
                  :rules="sumRules"
                  v-model="sum"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="$router.push({name: 'admin.market_transactions.index'})"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="blue darken-1"
            type="submit"
            :loading="saveLoading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

  </v-container>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Admin.MarketTransactions.Create",
  data: () => ({
    isFormValid: false,
    saveLoading: false,
    title: 'Neue Transaktion',
    sum: '',
    sumRules: [
      v => !!v || 'Summe ist erforderlich',
    ],
  }),
  computed: {
  },
  async mounted() {
    await this.initComponent()

  },
  methods: {
    ...mapActions('marketTransactions', {
      createTransaction: 'store'
    }),

    async initComponent() {
    },

    async save() {
      this.formResetValidation()

      setTimeout(() => {
        this.formValidate()

      },200)

      if (!this.isFormValid) {
        return
      }

      this.saveLoading = true
      try {
        let payload = {
          sum: this.sum,
        }

        let res = await this.createTransaction(payload)

        if (res) {
          await this.$router.push({name: 'admin.market_transactions.index'})
        }
      }
      finally {
        this.saveLoading = false
      }

    },
    formValidate () {
      return this.$refs.form.validate()
    },
    formReset () {
      this.$refs.form.reset()
    },
    formResetValidation () {
      this.$refs.form.resetValidation()
    },
  }
}
</script>

<style scoped>

</style>
