<template>
<div>

  <!-- ⬇⬇⬇ Dialogs ⬇⬇⬇ -->
  <v-dialog
    v-model="dialog"
    max-width="400"
  >
    <v-form
      ref="form"
      v-model="isFormValid"
      @submit.prevent="applyQuery"
    >
      <v-card>
        <v-card-title class="text-h5">
          Auszahlungsbetrag Marktplatz
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  autofocus
                  outlined
                  prepend-inner-icon="mdi-currency-usd"
                  :hint="`Geben Sie im Bereich von bis ${tax + 1} ein ${bonuses}`"
                  :rules="querySumRules()"
                  v-model="querySum"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  disabled
                  label="Blockchain Fee"
                  :value="tax"
                  prepend-inner-icon="mdi-currency-usd"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  outlined
                  disabled
                  label="zu Auszahlun"
                  prepend-inner-icon="mdi-currency-usd"
                  v-model="calcMoney"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            plain
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="green darken-1"
            type="submit"
            :loading="loadingSendQuery"
          >
            Senden
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
  <!-- ⬆⬆⬆ Dialogs ⬆⬆⬆ -->

  <v-row justify="space-between">
    <v-col
      cols="5"
      class="mt-4"
    >
      <div class="text-caption s-dark-color">Gesamt Umsatz</div>

<!--      TODO: event click need only testing -->
      <v-alert
        dense
        class="text-center"
        @click="permissionCheck = true"
      >
        {{ profit | toCurrency | convertCommaToSpace }}
      </v-alert>
    </v-col>
    <v-col
      cols="5"
      class="text-end mt-4"
    >
      <div class="text-caption s-dark-color">Dein Bonus</div>
      <v-alert
        dense
        class="text-center"
      >
        {{ bonuses | toCurrency | convertCommaToSpace }}
      </v-alert>
    </v-col>
  </v-row>


  <v-btn
    text
    outlined
    block
    class="mt-3"
    :disabled="!permissionCheck"
    @click="dialog = true"
  >Auszahlung beantragen</v-btn>

  <div class="mt-3 text-center grey--text text--darken-1">
    <p>Nachdem Sie eine Auszahlungsanfrage getätigt haben, kann es bis zu 48 Stunden dauern, bis der angefragte Betrag in Ihrem Wallet freigegeben wird.</p>
    <p>Auszahlungen aus dem Marktplatz erfolgen vorerst einmal monatlich, jeweils zwischen dem ersten und dritten Kalendertag.</p>
    <p>Der Bonus wird zu gleichen Teilen an registrierte Partner ausgeschüttet.</p>
  </div>
</div>
</template>

<script>
import {versionUri} from "@/services/config";
import {mapGetters} from "vuex";

export default {
  name: 'Components.HomeTotal',
  data: () => ({
    dialog: false,
    isFormValid: false,
    loadingSendQuery: false,
    permissionCheck: false,
    bonuses: 0,
    profit: 0,
    querySum: '',
    tax: 3,
  }),
  computed: {
    ...mapGetters('account', ['totals']),

    calcMoney() {
      let res = this.querySum - this.tax

      return res < 0 ? 0 : res
    },
  },
  mounted() {
    this.bonuses = this.totals.bonuses.market ?? 0
    this.profit = this.totals.profit.market ?? 0

    this.updatePermissionCheck()
  },
  methods: {
    querySumRules() {
      return [
        v => !!v || 'Aufstellen ist erforderlich',
        v => v <= this.bonuses || 'Maximalwert ' + this.bonuses,
        v => v > this.tax || 'Mindestwert ' + (this.tax + 1),
      ]
    },
    async applyQuery() {
      this.formResetValidation()

      setTimeout(() => {
        this.formValidate()
      },200)

      if (!this.isFormValid) {
        return
      }

      this.loadingSendQuery = true

      try {
        let payload = {
          sum: this.querySum,
          mode: 'get',
          type: 'market'
        }

        let url = versionUri + 'account/funds-queries'
        let response = await axios.post(url, payload)

        console.log('market get money response', this.categories)

        if (response.status === 200) {
          this.dialog = false
          await this.$store.dispatch('snackBar/run', 'Der Antrag auf Erhalt der Mittel wurde gesendet')
        }

      }
      finally {
        this.loadingSendQuery = false
      }
    },
    updatePermissionCheck() {
      let today = new Date().getDate();

      this.permissionCheck = today >= 1 && today <= 3
    },
    formValidate () {
      return this.$refs.form.validate()
    },
    formReset () {
      this.$refs.form.reset()
    },
    formResetValidation () {
      this.$refs.form.resetValidation()
    },
  }
}
</script>

<style lang="scss" scoped>

button:disabled,
button[disabled] {
  cursor: not-allowed;
  pointer-events: auto;
}

</style>
