<template>
  <v-card
    class="s-card mt-10 mx-auto"
    max-width="400"
  >
    <v-sheet
      rounded
      class="s-sheet mx-auto pa-3"
      :class="styleLine"
      elevation="12"
      max-width="calc(100% - 32px)"
    >
      <div>{{ title }}</div>
      <div>{{ percent }}%</div>
    </v-sheet>

    <v-card-title class="justify-center">
      <div>{{ number | toCurrency | convertCommaToSpace }}</div>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'ProfitCard',
  props: {
    title: String,
    percent: Number,
    number: {
      type: Number,
      default: 0
    },
    styleLine: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>

.s-card {
  border: 1px solid white;
}
.s-sheet {
  top: -24px;
  position: relative;
  margin-bottom: -24px;
  display: flex;
  justify-content: space-between;

  &._red {
    border: 1px solid red;
  }
  &._blue {
    border: 1px solid blue;
  }
}
</style>
