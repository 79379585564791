<template>
  <v-app>
    <v-navigation-drawer
      app
      disable-resize-watcher
      v-model="navigationDrawer"
    >
      <SideMenu
        :auth="auth"
        :pages="pages"
      />
    </v-navigation-drawer>

    <v-app-bar app>
<!--      <v-btn-->
<!--        icon-->
<!--        class="mr-3"-->
<!--        @click="navigationDrawer = !navigationDrawer"-->
<!--      >-->
<!--        <v-icon>mdi-menu</v-icon>-->
<!--      </v-btn>-->

      <div class=" fill-height mr-3">

        <router-link
          custom
          class="s-cur-pointer"
          v-slot="{ navigate }"
          :to="{name: 'page.home'}"
        >
          <span
            role="link"
            @click="navigate"
            @keypress.enter="navigate"
          >
            <Logo />
          </span>
        </router-link>
      </div>

      <v-spacer/>

      <v-fade-transition>
        <div
          v-if="!isHomeRoute"
          class="mr-3 d-none d-md-inline-block"
        >
          <v-btn
            icon
            exact
            :to="{name: 'account.home'}"
          ><v-icon>mdi-home</v-icon></v-btn>
        </div>

      </v-fade-transition>

      <div class="">
        <v-btn
          icon
          @click="$vuetify.theme.dark = !$vuetify.theme.dark"
        >
          <v-icon v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
          <v-icon v-else>mdi-weather-night</v-icon>
        </v-btn>
      </div>

      <div v-if="auth.is_paid">
        <v-badge
          bordered
          left
          inline
          overlap
          dot
          class="mx-3"
          color="green"
        >
          {{ auth.user_name }}
        </v-badge>
      </div>
      <div
        v-else
        class="mx-3"
      >
        {{ auth.user_name }}
      </div>
      <v-btn
        icon
        :loading="logoutLoading"
        @click="logout"
      ><v-icon>mdi-logout</v-icon></v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <SnackBar/>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <!-- If using vue-router -->
        <v-slide-x-transition mode="out-in">
          <router-view></router-view>
        </v-slide-x-transition>

      </v-container>

      <BottomMenu :pages="pages" />
    </v-main>

    <v-footer
      app
      absolute
      class="justify-center pa-2 pa-md-16"
    >

      <div
      >
        <v-btn
          small
          block
          outlined
          class="ma-2 grey--text text--darken-2"
        >
          DEUTSCH
        </v-btn>
        <v-btn
          small
          block
          outlined
          class="ma-2 grey--text text--darken-2"
        >
          OFFICIAL GG TELEGRAM
        </v-btn>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import SnackBar from '@/components/SnackBar'
import SideMenu from '@/components/SideMenu'
import Logo from '@/components/Logo'
import BottomMenu from '@/components/BottomMenu'

export default {
  name: 'AccountLayout',
  components: {
    SideMenu,
    SnackBar,
    Logo,
    BottomMenu
  },
  data: () => ({
    logoutLoading: false,
    navigationDrawer: false,
    group: null,
    pages: [
      {title: 'Daheim', path: 'account.home', icon: 'mdi-home'},
      {title: 'CROWD', path: 'account.crowd', icon: 'mdi-currency-usd'},
      {title: 'Profil', path: 'account.profile', icon: 'mdi-card-account-details'},
    ]
  }),
  mounted() {
  },
  computed: {
    ...mapGetters('auth', {
      auth: 'currentUser'
    }),

    isHomeRoute() {
      return this.$route.name === 'account.home'
    },
  },
  methods: {
    ...mapActions('auth', {
      authLogout: 'logout'
    }),

    async logout() {
      this.logoutLoading = true

      try {
        await this.authLogout()
        await this.$router.push({name: 'page.home'})
      }
      finally {
        this.logoutLoading = false
      }
    },
  }
}
</script>

<style scoped>

</style>
