
import Home from '@/views/account/Home'
import Profile from '@/views/account/Profile'
import Crowd from '@/views/account/Crowd'

export default [
  {
    path: '/account',
    name: 'account.home',
    component: Home,
    meta: { layout: 'account'},
  },
  {
    path: '/account/crowd',
    name: 'account.crowd',
    component: Crowd,
    meta: { layout: 'account'},
  },
  {
    path: '/account/profile',
    name: 'account.profile',
    component: Profile,
    meta: { layout: 'account'},
  },
]
