<template>
<div class="fill-height d-flex align-center">
  <div
    class="s-logo-icon"
  >
    <v-icon
      x-large
    >mdi-star-four-points</v-icon>
<!--    <v-icon x-large>mdi-star-four-points-outline</v-icon>-->
  </div>
  <div
    class="s-divider"
    :class="{'_light_theme': !isDarkTheme}"
  ></div>
  <div
    class="s-logo-text"
  >
    <div>Giebler</div>
    <div>Group</div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Logo',
  computed: {
    isDarkTheme() {
      return this.$vuetify.theme.dark
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.s-divider {
  border-style: solid;
  border-width: 1px;
  border-color: white;
  border-left: none;
  width: 1px;
  height: 70%;
  margin: 0 10px;

  &._light_theme {
    border-color: rgba(0, 0, 0, 0.87);
  }
}

.s-logo-text {
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 3px;
}
</style>
