<template>
  <v-container class="">

    <EditProfile
      @closed="$router.push({name: 'account.home'})"
    />
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import EditProfile from '@/views/account/components/EditProfile'

export default {
  name: 'Admin.Users.Edit',
  components: { EditProfile },
  methods: {

  }
}
</script>

<style scoped>

</style>
