import {versionUri} from '@/services/config'

const state = {
    totals: null,
    crowd: {
        isFoundsQuery: false
    },
    apiUrl: versionUri + 'account'
}

const getters = {
    totals: (state) => state.totals,
    crowd: (state) => state.crowd,
}

const mutations = {
    totals(state, payload) {
        state.totals = payload;
    },
    crowd(state, payload) {
        state.crowd = payload;
    },
}

const actions = {
    async loadTotals(context) {
        try {
            let response = await axios.get(context.state.apiUrl + '/totals')

            context.commit('totals', response.data.data)

            return response.status === 200
        }
        catch (error) {
            context.commit('totals', null)
            throw new Error(error)
        }
    },
    async loadCrowd(context) {
        try {
            let response = await axios.get(context.state.apiUrl + '/crowd')

            context.commit('crowd', response.data.data)

            return response.status === 200
        }
        catch (error) {
            context.commit('crowd', null)
            throw new Error(error)
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
