<template>
  <div>

<!-- ⬇⬇⬇ Dialogs ⬇⬇⬇ -->
    <v-dialog
      v-model="dialogSendMoney"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
<!--          CROWDFUNDING-->
        </v-card-title>
        <v-card-text>
          <p class="text-center">Betrag 100 USDT (Tron TRC20 Netzwerk)</p>
          <p>
            <v-text-field
              readonly
              append-icon="mdi-content-copy"
              :value="wallet"
              @click:append="copyWallet"
            />
          </p>
          <p>
            <span class="grey--text text--lighten-2 font-weight-bold">WICHTIG:</span> Bitte Wallet genau Kopieren und vergleiche die ersten und die
            Letzten drei Symbole, dass die mitkopiert sind
          </p>
          <p>Wenn überwiesen ist, bitte bestätigen:</p>

        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            plain
            @click="dialogSendMoney = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="green darken-1"
            :loading="loadingSendMoney"
            @click="applyQuery"
          >
            Einzahlung Bestätigen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogStepHelp"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Bedingungen
        </v-card-title>
        <v-card-text>
          <p>um die Stufe VII zu erreichen ist zu beachten:</p>

          <ul>
            <li>Regelung 30/70 ab der <u>Stufe VII</u></li>
            <li>keine Zeitliche Begrenzung</li>
          </ul>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            color="green darken-1"
            text
            @click="dialogStepHelp = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
<!-- ⬆⬆⬆ Dialogs ⬆⬆⬆ -->


    <div class="text-center">
      <v-chip
        label
        outlined
        @click="copyLink"
      >
        <span class="s-dark-color">CROWD Persönlicher Empfehlungslink</span>
        <v-icon
          right
        >mdi-content-copy</v-icon>
      </v-chip>
    </div>

    <v-container class="s-review-block my-3 my-sm-10 text-center">
      <v-row
        v-show="!(isMoneyRequestSent || auth.is_paid || this.crowd.isFoundsQuery)"
        align="center"
      >
        <v-col cols="12" sm="5" class="s-dark-color">
          Betrag <span class="grey--text text--lighten-2">100 USDT</span> (TRC20 Netzwerk)
        </v-col>
        <v-col cols="12" sm="3">

          <v-btn
            :light="$vuetify.theme.dark && !auth.is_paid"
            :disabled="auth.is_paid"
            color="amber"
            @click="dialogSendMoney = true"
          >
            Einzahlen
          </v-btn>
        </v-col>
        <v-col cols="12" sm="4">
          <pay-pal-button/>
        </v-col>
      </v-row>
    </v-container>

    <div class="text-center">
      <div class="text-h5 text-sm-h5">CROWDFUNDING PLATTFORM</div>
    </div>

    <div class="mt-2 text-center">
      <v-chip-group
        show-arrows
        center-active
        active-class="primary--text"
        class="s-chip-group"
        v-model="selectedStep"
      >
        <v-chip
          v-for="stepBtn in stepButtons" :key="stepBtn.id"

          label
          :value="stepBtn.id"
        >
          {{ stepBtn.title }}
        </v-chip>
      </v-chip-group>
    </div>

    <v-divider
      class="d-none d-sm-block mt-3"
    />
    <div style="height: 10px">
      <v-fade-transition>

        <v-progress-linear
          v-if="loadingCrowd"

          indeterminate
          rounded
          color="grey darken-3"
          height="10"
        ></v-progress-linear>
      </v-fade-transition>
    </div>

    <v-scroll-y-transition mode="in-out">
      <div v-if="showBlock === 'main'">

        <div class="s-dark-color mt-1 mt-sm-5">
          <p class="text-center">Bedingungen<br>(Tether (TRC20 Netzwerk)</p>

          <div
            style="max-width: 500px"
            class="mx-auto"
          >
            <b>Einmalbetrag 100 USDT</b>
            <p>Der Einmal-Betrag wird wie folgt verteilt: 30% fließen in die Auszahlung von Provisionen. 70% werden für den Aufbau des YouTube/ Radiostudios so wie das Immobilienprojekt verwendet. Die Belohnung wird von 30% des Betrages berechnet. Außerdem werden die Crowdpartner auch an dem gesamten Umsatz des Marktplatzes beteiligt.</p>

            <b>Risikohinweis</b>
            <p>Bei einer Investition solltest du dir immer bewusst sein, dass diese auch mit Risiken verbunden ist, die zu einem vollständigen Verlust deines eingesetzten Betrages führen kann. Daher sollte nur Kapital verwendet werden, das nicht zur täglichen Lebenshaltung benötigt wird.</p>

            <p>Bei GIEBLER GROUP erhältst du ein global gestreutes Portfolio aus verschiedenen Wirtschaftszweigen. Durch die breite <u>Diversifikation</u> wird das <b>Risiko minimiert</b>.</p>
          </div>
        </div>

      </div>
      <div v-else-if="showBlock === 'vip'">
        <v-container>
          <v-row>
            <v-col
              v-for="(vip, idx) in vips" :key="idx"
              cols="12"
              sm="4"
              class="text-center"
            >
              <v-icon
                x-large
              >{{ vip.icon }}</v-icon>
              <div>{{ vip.title }}</div>
              <div class="mt-1 mt-sm-4"><v-img :src="vip.img"/></div>
              <div class="mt-1 mt-sm-4">{{ vip.text }}</div>
            </v-col>
          </v-row>
        </v-container>
      </div>

      <div v-else-if="showBlock === 'steps'">
        <v-container class="s-review-block _list">
          <v-alert
            dense
            style="font-size: 14px"
          >
            Umsatz: {{ profit | numberFormat }}
          </v-alert>
          <v-row>
            <v-col cols="4">Stufe</v-col>
            <v-col cols="4">Prozent</v-col>
            <v-col cols="4">Verbleiben USDT</v-col>
          </v-row>

          <div v-for="step in stepBlocks" :key="step.num">

            <v-slide-y-transition>
              <v-row v-if="(selectedStep - 1) >= step.num">
                <v-col cols="4">
                  <v-alert
                    dense
                    class="text-center mb-0"
                    :color="step.active ? 'green' : ''"
                  >{{ step.title }}</v-alert>
                </v-col>
                <v-col cols="4" class="align-self-center">
                  <v-alert
                    dense
                    class="text-center mb-0"
                    :color="step.active ? 'green' : ''"
                  >{{ step.percent }}%</v-alert>
                </v-col>
                <v-col cols="4" class="s-help-icon">
                  <v-alert
                    dense
                    class="text-center mb-0"
                    :class="{'s-loading-alert': step.loading}"
                    :color="step.done ? 'green' : ''"
                  >

                    <v-icon
                      v-if="step.done"
                      small
                      style="margin-top: -1px"
                    >mdi-checkbox-marked-circle</v-icon>

                    <v-progress-linear
                      v-else-if="step.loading"

                      rounded
                      color="green"
                      background-color="transparent"
                      height="100%"
                      :value="step.countPercent"
                    >
                      {{ step.remainsSum | numberFormat }}
                    </v-progress-linear>

                    <div v-else>---</div>

                  </v-alert>
                  <v-btn
                    v-if="step.help ?? null"
                    icon
                    small
                    @click="dialogStepHelp = true"
                  >
                    <v-icon>mdi-chat-question</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-slide-y-transition>
          </div>

        </v-container>
      </div>
    </v-scroll-y-transition>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {versionUri} from "@/services/config";
import PayPalButton from "@/components/account/PayPalButton.vue";

export default {
  name: 'Account.Home',
  components: { PayPalButton },
  data: () => ({
    refLink: null,
    wallet: 'TL8sp5V5rfCdg5DEfTCcmyTCiVXRY9LR5b',
    dialogSendMoney: false,
    dialogStepHelp: false,
    loadingSendMoney: false,
    loadingCrowd: false,
    isMoneyRequestSent: false,
    selectedStep: 2,
    profit: 0,
    stepButtons: [
      {id: 1, title: 'Bedingungen'},
      {id: 2, title: 'Stufe I'},
      {id: 3, title: 'Stufe II'},
      {id: 4, title: 'Stufe III'},
      {id: 5, title: 'Stufe IV'},
      {id: 6, title: 'Stufe V'},
      {id: 7, title: 'Stufe VI'},
      {id: 8, title: 'Stufe VII'},
      {id: 9, title: 'Stufe VIII'},
      {id: 10, title: 'Stufe IX'},
      {id: 11, title: 'Stufe X'},
      {id: 12, title: 'VIP'},
    ],
    showBlock: null,

    stepBlocks: [
      {num: 1, title: 'I', percent: 10, total: 5000},
      {num: 2, title: 'II', percent: 15, total: 10000},
      {num: 3, title: 'III', percent: 20, total: 30000},
      {num: 4, title: 'IV', percent: 25, total: 90000},
      {num: 5, title: 'V', percent: 30, total: 250000},
      {num: 6, title: 'VI', percent: 35, total: 1000000, help: true},
      {num: 7, title: 'VII', percent: 45, total: 5000000},
      {num: 8, title: 'VIII', percent: 55, total: 15000000},
      {num: 9, title: 'IX', percent: 65, total: 35000000},
      {num: 10, title: 'X', percent: 70, total: null},
    ],

    vips: [
      {title: 'Stufe VII', icon: 'mdi-crown-circle-outline', img: '/images/travel.jpeg', text:'Lifestyle - Reise in Wert 10 000 $'},
      {title: 'Stufe VIII', icon: 'mdi-shield-crown-outline', img: '/images/car.jpg', text:'Luxus Auto in Wert 100 000 $ '},
      {title: 'Stufe X', icon: 'mdi-crown-outline', img: '/images/vila.jpg', text:'Haus Villa in Wert 500 000 $'},
    ]

  }),
  computed: {
    ...mapGetters('auth', {
      auth: 'currentUser'
    }),
    ...mapGetters('account', ['crowd']),
  },
  async mounted() {
    await this.loadAccountCrowd()
    this.profit = this.crowd.total
    this.initRefLink()
    this.calcStepRemains()
    this.initSelectedStep()

    this.showBlock = 'steps'
  },
  watch: {
    selectedStep(val, oldVal) {
      if (val === 1) {
        this.showBlock = null
        setTimeout(() => {
          this.showBlock = 'main'
        },200)

      }
      else if (val > 1 && val <= 11) {
        if (oldVal > 1 && oldVal <= 11) {
          return
        }

        this.showBlock = null
        setTimeout(() => {
          this.showBlock = 'steps'
        },200)
      }
      else if (val === 12) {
        this.showBlock = null
        setTimeout(() => {
          this.showBlock = 'vip'
        },200)
      }
      else {
        this.showBlock = null
      }
    }
  },
  methods: {
    ...mapActions('account', ['loadCrowd']),

    async loadAccountCrowd() {
      this.loadingCrowd = true

      let res = await this.loadCrowd()

      this.loadingCrowd = false
    },
    calcPercent(val1, val2) {
      return Math.ceil((val1 / val2) * 100)
    },
    async copyWallet() {
      await this.copyToClipboard(this.wallet, 'wallet')
    },
    async copyLink() {
      await this.copyToClipboard(this.refLink, 'link')
    },
    initRefLink() {
      this.refLink = location.origin + '/register?ref=' + this.auth.referral_token
    },

    async copyToClipboard(val, title = null) {
      let text = 'kopiert' + ' ' + title

      try {
        await navigator.clipboard.writeText(val);
        await this.$store.dispatch('snackBar/run', text)
      }
      catch($e) {
        alert('Cannot copy');
      }
    },

    initSelectedStep() {
        this.selectedStep = this.crowd.currentLevel.num + 1
    },

    calcStepRemains() {
      let steps = this.stepBlocks

      for (let i = 0; i < steps.length; i++) {
        if (!steps[i].total) {
          continue
        }

        let remainsSum = steps[i].total - this.profit
        let countPercent = this.calcPercent(this.profit, steps[i].total)

        remainsSum = remainsSum >= 0 ? remainsSum : 0
        countPercent = countPercent <= 100 ? countPercent : 100

        steps[i].remainsSum = remainsSum
        steps[i].countPercent = countPercent
        steps[i].active = this.crowd.currentLevel.num === steps[i].num
        steps[i].done = countPercent === 100
        steps[i].loading = countPercent >= 0 && countPercent < 100
      }

      this.stepBlocks = steps
    },

    async applyQuery() {

      this.loadingSendMoney = true

      try {
        let payload = {
          sum: 100,
          mode: 'send',
          type: 'crowd'
        }

        let url = versionUri + 'account/funds-queries'
        let response = await axios.post(url, payload)

        console.log('crowd applyQuery response', response)

        if (response.status === 200) {
          this.dialogSendMoney = false
          this.isMoneyRequestSent = true

          await this.$store.dispatch('snackBar/run', 'Anfrage gesendet')
        }
        else if (response.response.status === 409) {
          await this.$store.dispatch('snackBar/run', 'Die Anfrage wurde bereits versendet')
        }
      }
      catch (error) {
        throw new Error(error)
      }
      finally {
        this.loadingSendMoney = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.s-chip-group {
  &::v-deep .v-slide-group__content {
    justify-content: center;
  }
}
.s-review-block {
  max-width: 700px;
  margin-right: auto;
  margin-left: auto;
  min-height: 60px;

  &::v-deep .v-alert__content,
  &._list {
    font-size: 12px;
  }
}

.s-loading-alert {
  padding: 0;

  &::v-deep .v-alert__content {
    height: 34px;
  }
}

.s-help-icon {
  position: relative;

  & button {
    position: absolute;
    right: -10px;
    top: 0;
    z-index: 1;
  }
}
</style>
