<template>
  <v-data-table
    sort-by="date"
    class="elevation-1"
    no-data-text="Keine Daten verfügbar"
    loading-text="Artikel laden..."
    :loading="tableLoading"
    :headers="headers"
    :items="items"
    :items-per-page="30"
    :footer-props="tableFooterProps"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
        <v-toolbar-title>Benutzer</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>

        <v-btn
          dark
          color="primary"
          class="mb-2"
        :to="{name: 'admin.users.create'}"
        >
          Neuer Benutzer
        </v-btn>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn
                text
                color="blue darken-1"
                :loading="deleteLoading"
                @click="deleteItemConfirm"
              >OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.is_paid="{ value }">
      <v-simple-checkbox
        disabled
        :value="value"
      />
    </template>
    <template v-slot:item.is_banned="{ value }">
      <v-simple-checkbox
        disabled
        :value="value"
      />
    </template>
    <template v-slot:item.actions="{ item }">
      <div class="text-no-wrap">
        <v-icon
          class="mr-3"
          @click="$router.push({name: 'admin.users.edit', params: {id: item.id}})"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Admin.Users.Index',
  data: () => ({
    tableLoading: false,
    deleteLoading: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Date',
        align: 'start',
        value: 'created_at',
      },
      // {text: 'Vorname', value: 'first_name'},
      {text: 'Name', value: 'user_name'},
      {text: 'E-Mail', value: 'email'},
      // {text: 'PLZ', value: 'city_index'},
      // {text: 'Ort', value: 'city_name'},
      {text: 'Active', value: 'is_paid'},
      {text: 'Not Active', value: 'is_banned'},
      { text: 'Aktionen', value: 'actions', sortable: false, align: 'end' },
    ],
    tableFooterProps: {
      'items-per-page-options': [15,30,50,100,-1]
    },
    deleteItemId: null,
  }),

  computed: {
    ...mapGetters('users', {
      items: 'getAll'
    }),
  },

  created () {
    this.initialize()
  },

  methods: {
    ...mapActions('users', {
      loadItems: 'index',
      deleteItem: 'destroy'
    }),
    async initialize () {
      this.tableLoading = true

      try {
        await this.loadItems()
      }
      finally {
        this.tableLoading = false
      }

    },

    deleteItem (item) {
      this.deleteItemId = item.id
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      this.deleteLoading = true

      try {
        await this.deleteItem(this.deleteItemId)
        this.closeDelete()

        await this.initialize()
      }
      finally {
        this.deleteLoading = false
      }
    },

    closeDelete () {
      this.dialogDelete = false
      this.deleteItemId = null
    },
  },
}
</script>

<style scoped>

</style>
