
const state = {
    show: false,
    text: null,
}

const mutations = {
    isShow(state, payload) {
        state.show = payload;
    },
    hide(state) {
        state.show = false;
    },
    setText(state, payload) {
        state.text = payload;
    }
}

const getters = {
    isShow(state) {
        return state.show;
    },
    getText(state) {
        return state.text;
    }
}

const actions = {
    run({commit}, text) {
        commit('isShow', true);
        commit('setText', text);
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
