<template>
<div>
  <router-link
    custom
    class="text-h4 mt-0 mt-sm-14 text-sm-h2 text-lg-h1 s-title s-cur-pointer"
    v-slot="{ navigate }"
    :to="{name: 'page.home'}"
  >
    <div @click="navigate" @keypress.enter="navigate" role="link">GIEBLER GROUP</div>
  </router-link>

  <div class="text-md-subtitle-2 s-subtitle grey--text">L I F E S T Y L E – P R O D U K T E &nbsp;|&nbsp; D I E N S T L E I S T U N G E N</div>
</div>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<style scoped>

</style>
