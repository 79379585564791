
import Home from '@/views/admin/Home'
import Crowd from '@/views/admin/Crowd'
import Analytics from '@/views/admin/Analytics'

import companies from '@/router/admin/companies'
import marketTransactions from '@/router/admin/market_transactions'
import users from '@/router/admin/users'
import fundsQueries from '@/router/admin/funds_queries'

let routes = [
  {
    path: '/admin',
    name: 'admin.home',
    component: Home,
    meta: { layout: 'admin'},
  },
  {
    path: '/admin/crowd',
    name: 'admin.crowd.index',
    component: Crowd,
    meta: { layout: 'admin'},
  },
  {
    path: '/admin/analytics',
    name: 'admin.analytics.index',
    component: Analytics,
    meta: { layout: 'admin'},
  },
].concat(
    companies,
    marketTransactions,
    users,
    fundsQueries
)


export default routes
