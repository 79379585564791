import Vue from 'vue'
import Vuex from 'vuex'

import snackBar from './modules/snack_bar.state'
import auth from './modules/auth.state'
import companies from './modules/companies.state'
import marketTransactions from './modules/market_transactions.state'
import users from './modules/users.state'
import fundsQueries from './modules/funds_queries.state'
import account from './modules/account.state'

Vue.use(Vuex)

export default new Vuex.Store({
  namespace: true,
  strict: true,
  state: {
    visibilitySideBar: false
  },
  getters: {
    getVisibilitySideBar(state) {
      return state.visibilitySideBar
    }
  },
  mutations: {
    setShowSideBar(state, payload) {
      state.visibilitySideBar = payload
    },
    hideSideBar(state) {
      state.visibilitySideBar = false
    },
    showSideBar(state) {
      state.visibilitySideBar = true
    },
    toggleSideBar(state) {
      state.visibilitySideBar = !state.visibilitySideBar
    }
  },
  actions: {
  },
  modules: {
    snackBar,
    auth,
    companies,
    marketTransactions,
    users,
    fundsQueries,
    account
  }
})
