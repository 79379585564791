<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="4">

          <v-card
            :loading="loadingDashboardData"
          >
            <v-card-title class="text-h5">
              <v-icon x-large class="mr-3">mdi-cash-sync</v-icon>
              Funds queries
            </v-card-title>

            <v-slide-y-transition mode="out-in">
              <template v-if="!loadingDashboardData">
                <v-card-subtitle v-if="countFundsQueries">{{ countFundsQueries }} neue Fondsabfragen</v-card-subtitle>
                <v-card-subtitle v-else>keine neuen Geldabfragen</v-card-subtitle>

              </template>
            </v-slide-y-transition>

            <v-card-actions>
              <v-btn
                text
                :to="{name: 'admin.funds_queries.index'}"
              >
                See All
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {versionUri} from '@/services/config'

export default {
  name: 'Admin.Home',
  data: () => ({
    loadingDashboardData: true,
    countFundsQueries: null,
  }),
  mounted() {
    this.loadDashboardData()
  },
  methods: {
    async loadDashboardData() {
      this.loadingDashboardData = true

      try {
        let url = versionUri + 'admin/dashboard'
        let response = await axios.get(url)
        console.log('admin home loadDashboardData response', response)

        if (response.status === 200) {
          this.countFundsQueries = response.data.data.countFundsQueries
        }
      }
      finally {
        this.loadingDashboardData = false
      }
    }
  }
}
</script>

<style scoped>

</style>
