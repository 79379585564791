
import Index from '@/views/admin/market_transactions/Index'
import Create from '@/views/admin/market_transactions/Create'
import Edit from '@/views/admin/market_transactions/Edit'

export default [
  {
    path: '/admin/market_transactions',
    name: 'admin.market_transactions.index',
    component: Index,
    meta: { layout: 'admin'},
  },
  {
    path: '/admin/market_transactions/create',
    name: 'admin.market_transactions.create',
    component: Create,
    meta: { layout: 'admin'},
  },
  {
    path: '/admin/market_transactions/:id/edit',
    name: 'admin.market_transactions.edit',
    component: Edit,
    meta: { layout: 'admin'},
  },
]
