
import Index from '@/views/admin/users/Index'
import Create from '@/views/admin/users/Create'
import Edit from '@/views/admin/users/Edit'

export default [
  {
    path: '/admin/users',
    name: 'admin.users.index',
    component: Index,
    meta: { layout: 'admin'},
  },
  {
    path: '/admin/users/create',
    name: 'admin.users.create',
    component: Create,
    meta: { layout: 'admin'},
  },
  {
    path: '/admin/users/:id/edit',
    name: 'admin.users.edit',
    component: Edit,
    meta: { layout: 'admin'},
  },
]
