import {versionUri} from "@/services/config";

const state = {
  errors: null,
  user: {},
  isAuthenticated: false,
  apiUrl: versionUri + 'auth'
}

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
}

const mutations = {
  setError(state, error) {
    state.errors = error;
  },
  setAuth(state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
  },
  removeAuth(state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
  }
}

const actions = {
  async login(context, data) {
    let url = 'login'

    try {
      await axios.get('/sanctum/csrf-cookie')
      let response = await axios.post(url, data)

      return response.status === 204
    }
    catch (error) {
      context.commit('setError', error)
      throw new Error(error)
    }
  },
  async logout(context) {

    try {
      let response = await axios.post('logout')
      console.log('logout response', response)

      if (response.status === 204) {
        context.commit('removeAuth');
      }
    }
    catch (error) {
      context.commit('setError', error)
      throw new Error(error)
    }

  },
  async register(context, data) {
    let url = 'register'

    try {
      await axios.get('/sanctum/csrf-cookie')

      let response = await axios.post(url, data)
      console.log('register response', response)

      return response.status === 204;
    }
    catch (error) {
      context.commit('setError', error)
      throw new Error(error)
    }
  },
  async checkAuth(context, logout = true) {
    let url = context.state.apiUrl + `?logout=${logout}`

    try {
      let response = await axios.get(url)

      if (response.status !== 200) {
        context.commit('removeAuth');

        return
      }

      context.commit('setAuth', response.data.data)
    }
    catch (error) {
      context.commit('setError', error)
      throw new Error(error)
    }
  },
  async updateAuth(context, payload) {

    let url = context.state.apiUrl

    try {
      let response = await axios.patch(url, payload)

      if (response.status === 200) {
        context.commit('setAuth', response.data.data)

        return true
      }

      return false
    }
    catch (error) {
      context.commit('setError', error)
      throw new Error(error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
