<template>
  <v-container class="s-first-screen text-center d-flex flex-column">
    <Header/>

    <v-spacer/>

    <div>
      <v-form
      style="max-width: 600px;"
      class="mx-auto"
      ref="form"
      v-model="isFormValid"
      @submit.prevent="login"
    >
      <v-text-field
        required
        autofocus
        color="grey"
        label="E-Mail"
        :rules="emailRules"
        v-model="email"
      ></v-text-field>

      <v-text-field
        label="Password"
        color="grey"
        :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="passwordRules"
        :type="passwordVisible ? 'text' : 'password'"
        v-model="password"
        @click:append="passwordVisible = !passwordVisible"
      ></v-text-field>

      <v-checkbox
        color="amber"
        label="Merken"
        v-model="remember"
      />

      <div class="d-flex align-center mt-10 mt-md-16 ">
        <div class="mr-3">
          <v-btn
            x-large
            icon
            :to="{name:'page.home'}"
          >
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
        </div>

        <div class="flex-grow-1">
          <v-btn
            block
            x-large
            rounded
            class="s-btn"
            color="success"
            type="submit"
            :loading="loginLoading"
          >
            Login
          </v-btn>
        </div>
      </div>
    </v-form>
    </div>

    <v-spacer/>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import Header from '@/views/pages/components/Header'


export default {
  name: 'Page.Login',
  components: { Header },
  data: () => ({
    isFormValid: false,
    loginLoading: false,
    email: '',
    emailRules: [
      v => !!v || 'E-Mail ist erforderlich',
      v => /.+@.+\..+/.test(v) || 'E-mail muss gültig sein',
    ],
    password: '',
    passwordVisible: false,
    remember: true,
    passwordRules: [
      v => !!v || 'Passwort wird benötigt',
      v => v.length >= 8 || 'Mindestens 8 Zeichen',
    ],
  }),

  methods: {
    ...mapActions('auth', {
      toLogin: 'login',
      initAuth: 'checkAuth'
    }),

    async login() {
      this.formResetValidation()

      setTimeout(() => {
        this.formValidate()

      },200)

      if (!this.isFormValid) {
        return
      }
      let data = {
        email: this.email,
        password: this.password,
        remember: this.remember,
      }

      this.loginLoading = true

      try {
        let res = await this.toLogin(data)

        if (res) {
          await this.initAuth(false)
          let user = this.$store.getters['auth/currentUser']
          let pageName = user.role === 'admin'
            ? 'admin.home'
            : 'account.home'

          await this.$router.push({name: pageName})
        }

      }
      finally {
        this.loginLoading = false
      }
    },
    formValidate () {
      return this.$refs.form.validate()
    },
    formReset () {
      this.$refs.form.reset()
    },
    formResetValidation () {
      this.$refs.form.resetValidation()
    },
  },
}
</script>

<style lang="scss" scoped>

.s-first-screen {
  min-height: calc(100vh - 210px);
}

.s-btn::v-deep .v-btn__content{
  color: #000000;
}

@media (max-width: 959px) {
  .s-first-screen {
    min-height: calc(100vh - 120px);
  }
}

@media (max-width: 599px) {

}

</style>
