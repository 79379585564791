<template>
  <v-form
    style="max-width: 400px"
    class="mx-auto"
    ref="form"
    v-model="isFormValid"
    @submit.prevent="update"
  >
    <v-card
      :loading="cardLoading"
    >

      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-container><v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Vorname"
              v-model="first_name"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              label="Name"
              v-model="last_name"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="Username"
              :rules="userNameRules"
              v-model="user_name"
            />
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="E-Mail"
              :rules="emailRules"
              v-model="email"
            />
          </v-col>

          <v-col cols="12">

            <v-text-field
              counter
              label="Password"
              hint="Mindestens 8 Zeichen"
              :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules"
              :type="passwordVisible ? 'text' : 'password'"
              v-model="password"
              @click:append="passwordVisible = !passwordVisible"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="Wallet USDT"
              :rules="walletUsdtRules"
              v-model="wallet_usdt"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              label="PLZ"
              v-model="city_index"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Ort"
              v-model="city_name"
            />
          </v-col>
        </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="$emit('closed')"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="blue darken-1"
          type="submit"
          :loading="saveLoading"
        >
          Update
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'Account.Components.EditProfile',
  data: () => ({
    cardLoading: false,
    saveLoading: false,
    isFormValid: false,
    title: 'Profil bearbeiten',

    // Form
    user_name: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    city_index: '',
    city_name: '',
    wallet_usdt: '',

    passwordVisible: false,

    // Rules
    userNameRules: [
      v => !!v || 'Username ist erforderlich',
    ],
    walletUsdtRules: [
      v => !!v || 'Geldbörse ist erforderlich',
    ],
    emailRules: [
      v => !!v || 'E-mail ist erforderlich',
      v => /.+@.+\..+/.test(v) || 'E-mail muss gültig sein',
    ],
    passwordRules: [
      v => (!v || v.length >= 8) || 'Mindestens 8 Zeichen',
    ],
  }),
  computed: {
    ...mapGetters('auth', {
      user: 'currentUser',
    }),
  },
  async mounted() {
    await this.initComponent()
  },
  methods: {
    ...mapActions('auth', {
      updateAuth: 'updateAuth'
    }),

    async initComponent() {
      this.initItem()
    },

    initItem() {
      let item = Object.assign({}, this.user)

      this.first_name = item.first_name
      this.last_name = item.last_name
      this.user_name = item.user_name
      this.email = item.email
      this.city_index = item.city_index
      this.city_name = item.city_name
      this.wallet_usdt = item.wallet_usdt
    },

    async update() {
      this.formResetValidation()

      setTimeout(() => {
        this.formValidate()

      },200)

      if (!this.isFormValid) {
        return
      }

      this.saveLoading = true
      try {
        let payload = {
          first_name: this.first_name,
          last_name: this.last_name,
          user_name: this.user_name,
          email: this.email,
          city_index: this.city_index,
          city_name: this.city_name,
          wallet_usdt: this.wallet_usdt,
        }

        if (this.password) {
          payload.password = this.password
        }

        let res = await this.updateAuth(payload)

        if (res) {
          await this.$store.dispatch('snackBar/run', 'Gerettet')
          this.$emit('updated')
        }
      }
      finally {
        this.saveLoading = false
      }

    },
    formValidate () {
      return this.$refs.form.validate()
    },
    formReset () {
      this.$refs.form.reset()
    },
    formResetValidation () {
      this.$refs.form.resetValidation()
    },
  }
}
</script>

<style scoped>

</style>
