<template>
  <v-container>

    <v-form
      ref="form"
      v-model="isFormValid"
      @submit.prevent="save"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ title }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>

            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field
                  autofocus
                  label="Username"
                  :rules="userNameRules"
                  v-model="user_name"
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  label="Vorname"
                  v-model="first_name"
                />
              </v-col>

              <v-col cols="12" sm="4">
                <v-text-field
                  label="Name"
                  v-model="last_name"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="E-Mail"
                  :rules="emailRules"
                  v-model="email"
                />
              </v-col>

              <v-col cols="12">

                <v-text-field
                  counter
                  label="Password"
                  hint="Mindestens 8 Zeichen"
                  :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="passwordRules"
                  :type="passwordVisible ? 'text' : 'password'"
                  v-model="password"
                  @click:append="passwordVisible = !passwordVisible"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                  label="Wallet USDT"
                  v-model="wallet_usdt"
                />
              </v-col>

              <v-col sm="4">
                <v-text-field
                  label="PLZ"
                  v-model="city_index"
                />
              </v-col>
              <v-col sm="4">
                <v-text-field
                  label="Ort"
                  v-model="city_name"
                />
              </v-col>

              <v-col cols="4">
                <v-checkbox
                  label="ist verboten"
                  v-model="is_banned"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="$router.push({name: routeNameIndex})"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="blue darken-1"
            type="submit"
            :loading="saveLoading"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>

  </v-container>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Admin.Users.Create",
  data: () => ({
    isFormValid: false,
    saveLoading: false,
    title: 'Neuer Benutzer',
    routeNameIndex: 'admin.users.index',

    // Form
    user_name: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    city_index: '',
    city_name: '',
    wallet_usdt: '',
    is_banned: false,

    passwordVisible: false,

    // Rules
    userNameRules: [
      v => !!v || 'Username ist erforderlich',
    ],
    emailRules: [
      v => !!v || 'E-mail ist erforderlich',
      v => /.+@.+\..+/.test(v) || 'E-mail muss gültig sein',
    ],
    passwordRules: [
      v => !!v || 'Password ist erforderlich.',
      v => v.length >= 8 || 'Mindestens 8 Zeichen',
    ],
  }),
  computed: {
  },
  async mounted() {
    await this.initComponent()

  },
  methods: {
    ...mapActions('users', {
      createItem: 'store'
    }),

    async initComponent() {
    },

    async save() {
      this.formResetValidation()

      setTimeout(() => {
        this.formValidate()

      },200)

      if (!this.isFormValid) {
        return
      }

      this.saveLoading = true
      try {
        let payload = {
          user_name: this.user_name,
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          password: this.password,
          city_index: this.city_index,
          city_name: this.city_name,
          wallet_usdt: this.wallet_usdt,
          is_banned: this.is_banned,
        }

        let res = await this.createItem(payload)

        if (res) {
          await this.$router.push({name: this.routeNameIndex})
        }
      }
      finally {
        this.saveLoading = false
      }

    },
    formValidate () {
      return this.$refs.form.validate()
    },
    formReset () {
      this.$refs.form.reset()
    },
    formResetValidation () {
      this.$refs.form.resetValidation()
    },
  }
}
</script>

<style scoped>

</style>
