<template>
  <v-container>

    <div class="text-center mt-2">Profit - Center und Konten </div>

    <v-slide-y-transition mode="out-in">
    <v-progress-linear
      v-if="loadingData"

      indeterminate
      rounded
      color="grey darken-3"
      height="10"
    />

    <div v-else>

        <v-row class="mt-5">
        <v-col cols="12" md="6">
          <div class="text-h5 text-center">MARKTPLATZ</div>
          <div class="text-subtitle-1 text-center">{{ profitMarket | toCurrency | convertCommaToSpace }}</div>
          <v-row>
            <v-col cols="12" sm="6">
              <ProfitCard
                title="Geschäftspartner"
                :percent="65"
                :number="market1"
                style-line="_red"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <ProfitCard
                title="Firma"
                :percent="35"
                :number="market2"
                style-line="_red"
              />
            </v-col>
          </v-row>

          <v-divider class="mt-5"/>

          <v-row>
            <v-col cols="12" sm="6">
              <ProfitCard
                title="Incentivs"
                :percent="5"
                :number="market3"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <ProfitCard
                title="Reserve Konto"
                :percent="5"
                :number="market4"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <ProfitCard
                title="Sonstiges Konto"
                :percent="5"
                :number="market5"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <ProfitCard
                title="Firma: zu Verfügung"
                :percent="20"
                :number="market6"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-divider class="d-none d-md-block" inset vertical/>

        <v-col cols="12" md="6">

          <div class="text-h5 text-center">CROWDFUNDING</div>
          <div class="text-subtitle-1 text-center">{{ profitCrowd | toCurrency | convertCommaToSpace }}</div>
          <v-row>
            <v-col cols="12" sm="6">
              <ProfitCard
                title="C - Bonus"
                :percent="30"
                :number="crowd1"
                style-line="_blue"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <ProfitCard
                title="C - YouTube/Immo."
                :percent="70"
                :number="crowd2"
                style-line="_blue"
              />
            </v-col>
          </v-row>

          <v-divider class="mt-5"/>

          <v-row>
            <v-col cols="12" sm="4">
              <ProfitCard
                title="C-Struktur - Bonus"
                :percent="70"
                :number="crowd3"
                style-line="_red"
              />
            </v-col>

            <v-col cols="12" sm="4">
              <ProfitCard
                title="CROWD Bonus "
                :percent="5"
                :number="crowd4"
                style-line="_red"
              />
            </v-col>

            <v-col cols="12" sm="4">
              <ProfitCard
                title="Reserve Konto"
                :percent="5"
                :number="crowd5"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <ProfitCard
                title="Incentivs"
                :percent="5"
                :number="crowd6"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <ProfitCard
                title="Firma: zu Verfügung"
                :percent="15"
                :number="crowd7"
              />
            </v-col>
          </v-row>

        </v-col>
      </v-row>

    </div>
    </v-slide-y-transition>
  </v-container>
</template>

<script>
import {versionUri} from '@/services/config'
import ProfitCard from '@/components/admin/ProfitCard'

export default {
  name: 'Admin.Analytics',
  components: { ProfitCard },
  data: () => ({
    loadingData: true,
    profitMarket: 0,
    profitCrowd: 0,
    market1: 0,
    market2: 0,
    market3: 0,
    market4: 0,
    market5: 0,
    market6: 0,
    crowd1: 0,
    crowd2: 0,
    crowd3: 0,
    crowd4: 0,
    crowd5: 0,
    crowd6: 0,
    crowd7: 0,
  }),
  async mounted() {
    await this.loadAnalyticsData()
  },
  methods: {
    async loadAnalyticsData() {
      this.loadingData = true

      try {
        let url = versionUri + 'admin/analytics'
        let response = await axios.get(url)
        console.log('admin analytics loadData response', response)

        if (response.status === 200) {
          this.init(response.data.data)
        }
      }
      finally {
        this.loadingData = false
      }
    },
    init(data) {
      this.profitMarket = data.profit.market
      this.profitCrowd = data.profit.crowd

      this.market1 = this.calcPercent(data.profit.market, 0.65)
      this.market2 = this.calcPercent(data.profit.market, 0.35)
      this.market3 = this.calcPercent(data.profit.market, 0.05)
      this.market4 = this.calcPercent(data.profit.market, 0.05)
      this.market5 = this.calcPercent(data.profit.market, 0.05)
      this.market6 = this.calcPercent(data.profit.market, 0.20)

      this.crowd1 = this.calcPercent(data.profit.crowd, 0.3)
      this.crowd2 = this.calcPercent(data.profit.crowd, 0.7)
      this.crowd3 = this.calcPercent(this.crowd1, 0.7)
      this.crowd4 = this.calcPercent(this.crowd1, 0.05)
      this.crowd5 = this.calcPercent(this.crowd1, 0.05)
      this.crowd6 = this.calcPercent(this.crowd1, 0.05)
      this.crowd7 = this.calcPercent(this.crowd1, 0.15)
    },
    calcPercent(num, percent) {
      return parseFloat((num * percent).toFixed(2))
    }
  }
}
</script>

<style lang="scss" scoped>
.s-divider {
}
</style>
