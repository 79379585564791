import {versionUri} from '@/services/config'

const state = {
    items: [],
    item: null,
    apiUrl: versionUri + 'admin/market-transactions'
}

const getters = {
    getAll: (state) => state.items,
    getOne: (state) => state.item,
}

const mutations = {
    putAll(state, payload) {
        state.items = payload;
    },
    putOne(state, payload) {
        state.item = payload;
    },
}

const actions = {
    async index(context) {
        try {
            let response = await axios.get(context.state.apiUrl)

            context.commit('putAll', response.data.data)
        }
        catch (error) {
            throw new Error(error)
        }
    },
    async store(context, payload) {
        try {
            let response = await axios.post(context.state.apiUrl, payload)
            console.log('marketTransactions store response', response)

            return response.status === 200
        }
        catch (error) {
            throw new Error(error)
        }
    },
    async show(context, id) {
        try {
            let response = await axios.get(`${context.state.apiUrl}/${id}`)
            console.log('marketTransactions show response', response)

            context.commit('putOne', response.data.data)
        }
        catch (error) {
            throw new Error(error)
        }
    },
    async update(context, {id, payload}) {
        try {
            let response = await axios.patch(`${context.state.apiUrl}/${id}`, payload)
            console.log('marketTransactions update response', response)

            return response.status === 200
        }
        catch (error) {
            throw new Error(error)
        }
    },
    async destroy(context, id) {
        try {
            let response = await axios.delete(`${context.state.apiUrl}/${id}`)
            console.log('marketTransactions destroy response', response)

            return response.status === 200
        }
        catch (error) {
            throw new Error(error)
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
