import store from '@/store'
import router from '@/router'

const success = (response) => {

  if (response.data.errors) {
    let str = createErrorStr(response)
    store.dispatch('snackBar/run', str)
  }
  else if (response.data.msg ?? false) {
    let message = response.data.msg
    store.dispatch('snackBar/run', message)
  }

  return response
}
const error = (error) => {
  if (error.response.status === 303) {
    store.dispatch('auth/checkAuth')
      .then(res => {

        console.log('error 303 response', error)
        let user = store.getters['auth/currentUser']
        let pageName = user.role === 'admin'
            ? 'admin.home'
            : 'account.home'

        router.push({name: pageName})
      })
  }

  if (error.response.status === 401) {
    let url = error.response.config.url
    const params = new URL(error.request.responseURL).searchParams;
    console.log('error 401 response', error)

    if (params.get('logout') === 'true') {
      store.commit('auth/removeAuth')
      router.push({name: 'page.login'})
    }
  }

  if (error.response.status === 422) {
    let str = createErrorStr(error.response)
    console.log('error 422 response', str)
    store.dispatch('snackBar/run', str)
  }

  if (error.response.status === 500) {
    console.log('error 500 response', error.response)
    let msg = 'Server error'

    if (error.response.data.message) {
      msg = msg + ': ' + error.response.data.message
    }

    store.dispatch('snackBar/run', msg)
  }

  return error
}

let createErrorStr = (response) => {
  const errors = response.data.errors
  let str = response.data.message || ''

  for (let key in errors) {
    str = str + "<br><br>" + key

    for (let error of errors[key]) {
      str = str + "<br>" + error
    }
  }

  return str;
}

export {success, error}
