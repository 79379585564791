<template>
  <v-container >
      <v-card
        class="mx-auto"
        max-width="400"
        :loading="loadingCard"
      >

        <v-card-title>Fondsabfragen</v-card-title>

        <v-slide-y-transition mode="out-in">

          <v-list
            v-if="item && !loadingCard"
            class="transparent"
          >
            <v-list-item>
              <v-list-item-title>Status</v-list-item-title>

              <v-list-item-subtitle class="text-right">
                <v-icon v-if="item.status.name === 'new'" color="yellow accent-2">mdi-new-box</v-icon>
                <v-icon v-else-if="item.status.name === 'accepted'" color="green">mdi-check-outline</v-icon>
                <v-icon v-else-if="item.status.name === 'denied'" color="red">mdi-close-octagon</v-icon>
                <span v-else>{{item.status.name}}</span>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>Benutzer</v-list-item-title>

              <v-list-item-subtitle class="text-right">
                <v-btn
                  text
                  :to="{name: 'admin.users.edit', params: {id: item.user.id}}"
                  >
                  {{ item.user.user_name }}
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>Brieftasche eingeben</v-list-item-title>

              <v-list-item-subtitle class="text-right">
                <v-icon v-if="item.type_wallet === 'market'">mdi-shopping-outline</v-icon>
                <v-icon v-else>mdi-finance</v-icon>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>Transaktion eingeben</v-list-item-title>

              <v-list-item-subtitle class="text-right">
                <v-icon v-if="item.type_transaction === 'send'" color="green">mdi-cash-plus</v-icon>
                <v-icon v-else color="red">mdi-cash-minus</v-icon>
              </v-list-item-subtitle>
            </v-list-item>

            <v-list-item>
              <v-list-item-title>Summe</v-list-item-title>

              <v-list-item-subtitle class="text-right">
                {{ item.sum }}
              </v-list-item-subtitle>
            </v-list-item>

          </v-list>
          <v-card-subtitle v-else-if="!item && !loadingCard">Keine Daten verfügbar</v-card-subtitle>
        </v-slide-y-transition>

        <v-divider/>

        <v-card-actions>
          <v-btn
            text
            plain
            :to="{name: routeNameIndex}"
          >
            Back
          </v-btn>
          <v-spacer/>

          <v-slide-y-transition>
            <div v-if="item && !loadingCard && (item.status.id === 1 || item.status.id === 2)">

              <v-btn
                :loading="loadingDenied"
                class="ma-1"
                color="error"
                plain
                @click="denied"
              >
                Abbrechen
              </v-btn>
              <v-btn
                :loading="loadingAccepted"
                class="ma-1"
                color="success"
                plain
                @click="accepted"
              >
                Akzeptieren
              </v-btn>

            </div>
          </v-slide-y-transition>
        </v-card-actions>
      </v-card>
  </v-container>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex'
import {versionUri} from '@/services/config'

export default {
  name: "Admin.FundsQueries.Show",
  data: () => ({
    loadingCard: true,
    loadingDenied: false,
    loadingAccepted: false,
    routeNameIndex: 'admin.funds_queries.index',

  }),
  computed: {
    ...mapGetters('fundsQueries', {
      item: 'getOne',
    }),
  },
  async mounted() {
    await this.initComponent()
  },
  methods: {
    ...mapActions('fundsQueries', {
      loadItem: 'show'
    }),
    ...mapMutations('fundsQueries', {
      putQuery: 'putOne'
    }),

    async initComponent() {
      try {
        this.loadingCard = true
        await this.loadItem(this.$route.params.id)

      }
      finally {
        this.loadingCard = false
      }
    },
    async accepted() {

      this.loadingAccepted = true

      try {
        let url = versionUri + `admin/funds-queries/${this.$route.params.id}/accepted`

        let response = await axios.patch(url)

        if (response.status === 200) {
          await this.putQuery(response.data.data)
          await this.$store.dispatch('snackBar/run', 'Akzeptiert')
        }

      }
      finally {
        this.loadingAccepted = false
      }

    },
    async denied() {

      this.loadingDenied = true

      try {
        let url = versionUri + `admin/funds-queries/${this.$route.params.id}/denied`

        let response = await axios.patch(url)

        if (response.status === 200) {
          await this.putQuery(response.data.data)
          await this.$store.dispatch('snackBar/run', 'Abgesagt')
        }

      }
      finally {
        this.loadingDenied = false
      }
    },
  }
}
</script>

<style scoped>

</style>
