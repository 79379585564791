<template>
  <div>
    <v-list>
      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ auth.first_name }}
          </v-list-item-title>
          <v-list-item-subtitle>{{ auth.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list
      nav
      dense
    >
      <v-list-item
        exact
        v-for="page in pages" :key="page.title"
        :to="{name: page.path}"
      >
        <v-list-item-icon>
          <v-icon>{{ page.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>{{ page.title}}</v-list-item-title>
      </v-list-item>

    </v-list>

  </div>
</template>

<script>
export default {
  name: 'Components.SideMenu',
  props: ['auth', 'pages'],
  data: () => ({
  }),
}
</script>

<style scoped>

</style>
