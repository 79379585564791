
import Home from '@/views/pages/Home'
import Register from '@/views/pages/Register'
import Login from '@/views/pages/Login'
import NotFound from '@/views/pages/NotFound'

export default [
  {
    path: '/',
    name: 'page.home',
    component: Home,
    meta: { layout: 'page'},
  },
  {
    path: '/register',
    name: 'page.register',
    component: Register,
    meta: { layout: 'page'},
  },
  {
    path: '/login',
    name: 'page.login',
    component: Login,
    meta: { layout: 'page'},
  },
  { path: '*', component: NotFound }
]
