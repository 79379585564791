<template>
  <v-container>

  <v-form
    ref="form"
    v-model="isFormValid"
    @submit.prevent="update"
  >
    <v-card
      :loading="cardLoading"
    >

      <v-card-title>
        <span class="text-h5">{{ title }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name"
                :rules="nameRules"
                v-model="name"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-combobox
                label="Kategorie"
                :rules="categoryRules"
                :items="categories"
                v-model="category_name"
              ></v-combobox>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="url"
                label="Webseite"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="commission"
                label="Kommission"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="is_deactivated"
                label="Deaktiviert"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          @click="$router.push({name: 'admin.companies.index'})"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          color="blue darken-1"
          type="submit"
          :loading="saveLoading"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Admin.Companies.Edit",
  data: () => ({
    cardLoading: false,
    saveLoading: false,
    isFormValid: false,
    title: 'Firma bearbeiten',
    name: '',
    category_name: '',
    url: '',
    commission: null,
    is_deactivated: false,
    deactivated_at: '',
    nameRules: [
      v => !!v || 'Name ist erforderlich',
    ],
    categoryRules: [
      v => !!v || 'Kategorie ist erforderlich',
    ],
  }),
  computed: {
    ...mapGetters('companies', {
      getCompany: 'company',
      categories: 'categories'
    }),
  },
  async mounted() {
    await this.initComponent()
  },
  methods: {
    ...mapActions('companies', {
      loadCompany: 'show',
      updateCompany: 'update',
      loadCategories: 'getCategories'
    }),

    async initComponent() {
      try {
        this.cardLoading = true
        await this.loadCompany(this.$route.params.id)
        await this.loadCategories()

        this.initItem()
      }
      finally {
        this.cardLoading = false
      }
    },

    initItem() {
      let item = Object.assign({}, this.getCompany)

      this.name = item.name
      this.category_name = item.category_name
      this.url = item.url
      this.commission = item.commission
      this.is_deactivated = item.is_deactivated
    },

    update() {
      this.formResetValidation()

      setTimeout(() => {
        this.formValidate()

      },200)

      if (!this.isFormValid) {
        return
      }

      this.saveLoading = true

      setTimeout(() => {
        this.updateWrapper()
      }, 100)
    },

    async updateWrapper() {

      try {
        let payload = {
          name: this.name,
          category_name: this.category_name,
          url: this.url,
          commission: this.commission,
          is_deactivated: this.is_deactivated,
        }

        let res = await this.updateCompany({
          id: this.$route.params.id,
          payload
        })

        if (res) {
          await this.$router.push({name: 'admin.companies.index'})
        }
      }
      finally {
        this.saveLoading = false
      }
    },
    formValidate () {
      return this.$refs.form.validate()
    },
    formReset () {
      this.$refs.form.reset()
    },
    formResetValidation () {
      this.$refs.form.resetValidation()
    },
  }
}
</script>

<style scoped>

</style>
