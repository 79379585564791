import Vue from 'vue'

Vue.filter('numberFormat', function(value) {
  if (!Number.isInteger(value)) {
    return value
  }

  return new Intl.NumberFormat().format(value)
})

Vue.filter('toCurrency', function (value) {
  if (typeof value !== 'number') {
    return value;
  }

  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  });

  return formatter.format(value);
});

Vue.filter('convertCommaToSpace', function (value) {
  return value.replace(',', ' ');
});

Vue.filter('appendSymbol', function(value, arg) {
  if (!value || !arg) {
    return value
  }

  return value + arg
})

Vue.filter('prependSymbol', function(value, arg) {
  if (!value || !arg) {
    return value
  }

  return arg + value
})
