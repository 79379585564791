<template>
<div>
  <v-row justify="space-between">
    <v-col
      cols="5"
      class="mt-4"
    >
      <div class="text-caption s-dark-color">Gesamt Umsatz</div>
      <v-alert
        dense
        class="text-center"
      >
        {{ profit | toCurrency | convertCommaToSpace }}
      </v-alert>
    </v-col>
    <v-col
      cols="5"
      class="text-end mt-4"
    >
      <div class="text-caption s-dark-color">Gesamt Bonus</div>
      <v-alert
        dense
        class="text-center"
      >
        {{ bonuses | toCurrency | convertCommaToSpace }}
      </v-alert>
    </v-col>
  </v-row>
  <v-row justify="space-between">
    <v-col
      cols="5"
    >
      <div class="text-caption s-dark-color">Partner Direkt </div>
      <v-alert
        dense
        class="text-center"
      >
        {{ totals.countDirectPartners | numberFormat}}
      </v-alert>
    </v-col>
    <v-col
      cols="5"
      class="text-end"
    >
      <div class="text-caption s-dark-color">Partner Gesamt</div>
      <v-alert
        dense
        class="text-center"
      >
        {{ totals.countAllPartners | numberFormat}}
      </v-alert>
    </v-col>
  </v-row>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'HomeTotal',
  data: () => ({
    bonuses: 0,
    profit: 0,
  }),
  computed:{
    ...mapGetters('account', ['totals']),

  },
  mounted() {
    this.bonuses = (this.totals.bonuses.market + this.totals.bonuses.crowd)
    this.profit = (this.totals.profit.market + this.totals.profit.crowd)
  }
}
</script>

<style scoped>

</style>
