<template>
  <div class="text-center">
    <Header/>

    <h1>Not Found!</h1>
    <p>
      <router-link :to="{name: 'page.home'}">Go home</router-link>
    </p>
  </div>
</template>

<script>
import Header from '@/views/pages/components/Header'


export default {
  name: 'NotFound',
  components: { Header },
}

</script>

<style scoped>

center {
  margin: 15vw;
}

h1 {
  font-size: 2em;
}

</style>
