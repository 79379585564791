<template>
  <v-app>
    <v-navigation-drawer
      app
      disable-resize-watcher
      v-model="navigationDrawer"
    >
      <SideMenu
        :auth="auth"
        :pages="pages"
      />
    </v-navigation-drawer>

    <v-app-bar app>

<!--      <v-btn-->
<!--        icon-->
<!--        class="mr-3"-->
<!--        @click="navigationDrawer = !navigationDrawer"-->
<!--      >-->
<!--        <v-icon>mdi-menu</v-icon>-->
<!--      </v-btn>-->

      <div class="fill-height mr-3">

        <router-link
          custom
          class="s-cur-pointer"
          v-slot="{ navigate }"
          :to="{name: 'page.home'}"
        >
          <span
            role="link"
            @click="navigate"
            @keypress.enter="navigate"
          >
            <Logo />
          </span>
        </router-link>
      </div>

      <v-tabs
        align-with-title
        show-arrows
        class="d-none d-md-block"
      >
        <v-tab
          v-for="(page, idx) in pages" :key="idx"
          exact
          :to="{name: page.path}"
        >{{ page.title }}</v-tab>
      </v-tabs>

      <v-spacer/>

      <div class="">
        <v-btn
          icon
          @click="$vuetify.theme.dark = !$vuetify.theme.dark"
        >
          <v-icon v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
          <v-icon v-else>mdi-weather-night</v-icon>
        </v-btn>
      </div>

      <div class="mx-3">{{ auth.user_name }}</div>
      <v-btn
        icon
        :loading="logoutLoading"
        @click="logout"
      ><v-icon>mdi-logout</v-icon></v-btn>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <SnackBar/>

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <!-- If using vue-router -->
        <v-slide-x-transition mode="out-in">
          <router-view></router-view>
        </v-slide-x-transition>
      </v-container>

      <BottomMenu :pages="pages" />
    </v-main>
  </v-app>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import SnackBar from '@/components/SnackBar'
import SideMenu from '@/components/SideMenu'
import BottomMenu from '@/components/BottomMenu'
import Logo from '@/components/Logo'

export default {
  name: 'AdminLayout',
  components: {
    SideMenu,
    BottomMenu,
    SnackBar,
    Logo
  },
  data: () => ({
    logoutLoading: false,
    navigationDrawer: false,
    group: null,
    pages: [
      {title: 'Home', path: 'admin.home', icon: 'mdi-home'},
      {title: 'Crowd', path: 'admin.crowd.index', icon: 'mdi-finance'},
      {title: 'Analytik', path: 'admin.analytics.index', icon: 'mdi-poll'},
      {title: 'Firmen', path: 'admin.companies.index', icon: 'mdi-office-building-cog-outline'},
      {title: 'Marktplatz-Umsatz', path: 'admin.market_transactions.index', icon: 'mdi-store-plus-outline'},
      {title: 'Benutzer', path: 'admin.users.index', icon: 'mdi-account-group'},
      {title: 'Fondsabfragen', path: 'admin.funds_queries.index', icon: 'mdi-cash-sync'},
    ]
  }),
  mounted() {
  },
  computed: {
    ...mapGetters('auth', {
      auth: 'currentUser'
    }),
  },
  methods: {
    ...mapActions('auth', {
      authLogout: 'logout'
    }),

    async logout() {
      this.logoutLoading = true

      try {
        await this.authLogout()
        await this.$router.push({name: 'page.home'})
      }
      finally {
        this.logoutLoading = false
      }
    },
  }
}
</script>

<style lang="scss">

</style>
