<template>
  <v-container class="s-first-screen text-center d-flex flex-column">
    <Header/>

    <v-spacer/>

    <div>
      <v-form
      ref="form"
      style="max-width: 600px"
      class="mx-auto"
      v-model="isFormValid"
      @submit.prevent="register"
    >
      <v-text-field
        required
        autofocus
        color="grey"
        v-model="user_name"
        :rules="userNameRules"
        label="Username"
      ></v-text-field>

      <v-text-field
        required
        label="E-Mail"
        color="grey"
        :rules="emailRules"
        v-model="email"
      ></v-text-field>

      <v-text-field
        counter
        label="Password"
        color="grey"
        hint="Mindestens 8 Zeichen"
        :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="passwordRules"
        :type="passwordVisible ? 'text' : 'password'"
        v-model="password"
        @click:append="passwordVisible = !passwordVisible"
      ></v-text-field>

      <v-text-field
        counter
        color="grey"
        label="Passwort Wiederholen"
        :rules="passwordConfirmationRules()"
        :type="passwordVisible ? 'text' : 'password'"
        v-model="passwordConfirmation"
      ></v-text-field>

      <v-checkbox
        v-model="checkbox"
        color="amber"
        :rules="checkboxRules"
      >
        <template v-slot:label>
          <div>
            Vereinbarung und die
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a
                  target="_blank"
                  href="/#"
                  @click.prevent="getFile('GGroup-AGB`S.pdf')"
                  v-on="on"
                >AGBs</a>
              </template>
              Öffnet in neuem Fenster
            </v-tooltip>
            gelesen.
          </div>
        </template>
      </v-checkbox>

      <div class="d-flex align-center mt-10">
        <div class="mr-3">
          <v-btn
            x-large
            icon
            :to="{name:'page.home'}"
          >
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
        </div>

        <div class="flex-grow-1">
          <v-btn
            block
            x-large
            rounded
            class="s-btn"
            type="submit"
            color="success"
            :loading="registerLoading"
          >
            Partner werden
          </v-btn>

        </div>
      </div>
    </v-form>
    </div>

    <v-spacer/>
  </v-container>
</template>

<script>
import {mapActions} from "vuex";
import Header from '@/views/pages/components/Header'


export default {
  name: 'Page.Register',
  components: { Header },
  data: () => ({
    isFormValid: false,
    registerLoading: false,
    user_name: '',
    email: '',
    password: '',
    passwordConfirmation: '',
    passwordVisible: false,
    checkbox: false,
    userNameRules: [
      v => !!v || 'Name ist erforderlich',
    ],
    emailRules: [
      v => !!v || 'E-mail ist erforderlich',
      v => /.+@.+\..+/.test(v) || 'E-mail muss gültig sein',
    ],
    passwordRules: [
      v => !!v || 'Password ist erforderlich.',
      v => v.length >= 8 || 'Mindestens 8 Zeichen',
    ],
    checkboxRules: [
      v => !!v || 'Sie müssen zustimmen, um fortzufahren!'
    ]
  }),

  methods: {
    ...mapActions('auth', {
      toRegister: 'register',
      initAuth: 'checkAuth'
    }),

    passwordConfirmationRules() {
      return [
        v => !!v || 'Password ist erforderlich.',
        v => v === this.password || 'Passwörter stimmen nicht überein',
      ]
    },
    getFile(file) {
      window.open('documents/' + file, '_blank');
    },
    async register() {
      this.formResetValidation()

      setTimeout(() => {
        this.formValidate()
      },200)

      if (!this.isFormValid) {
        return
      }

      let data = {
        user_name: this.user_name,
        email: this.email,
        password: this.password,
        password_confirmation: this.passwordConfirmation,
      }

      if (this.$route.query.ref) {
        data.ref = this.$route.query.ref
      }

      this.registerLoading = true

      try {
        let res = await this.toRegister(data)

        if (res) {
          await this.initAuth(false)
          let user = this.$store.getters['auth/currentUser']
          let pageName = user.role === 'admin'
            ? 'admin.home'
            : 'account.home'

          await this.$router.push({name: pageName})
        }
      }
      finally {
        this.registerLoading = false
      }
    },
    formValidate () {
      return this.$refs.form.validate()
    },
    formReset () {
      this.$refs.form.reset()
    },
    formResetValidation () {
      this.$refs.form.resetValidation()
    },
  },
}
</script>

<style lang="scss" scoped>

.s-first-screen {
  min-height: calc(100vh - 210px);
}

.s-btn::v-deep .v-btn__content{
  color: #000000;
}

@media (max-width: 959px) {
  .s-first-screen {
    min-height: calc(100vh - 120px);
  }
}

@media (max-width: 599px) {

}

</style>
