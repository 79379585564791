import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import pages from './pages'
import admin from './admin'
import account from './account'

let routes = [].concat(
    pages,
    admin,
    account
)

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {

  const authUser = store.getters['auth/currentUser']
  const isAuth = store.getters['auth/isAuthenticated']
  const layout = to.meta.layout

  if (isAuth && (to.name === 'page.login' || to.name === 'page.register')) {
    if (authUser.role === 'admin') {
      next({name: 'admin.home'})
    }
    else {
      next({name: 'account.home'})
    }
  }
  else if (isAuth && authUser.role === 'admin' && layout === 'account') {
    router.push({name: 'admin.home'}) // router.push used for remove error exception
  }
  else if (isAuth && authUser.role === 'user' && layout === 'admin') {
    router.push({name: 'account.home'}) // router.push used for remove error exception
  }
  else if (!isAuth && (layout === 'admin' || layout === 'account')) {
    next({name: 'page.login'})
  }
  else {
    next()
  }
})

export default router
